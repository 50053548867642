import lunar from "cky-lunar-calendar";
import * as firebase from "firebase";
import 'firebase/auth';

import Cookies from "js-cookie";
import _ from "lodash";
import md5 from "md5";
import thongTinKhoaHoc from "../image/infoSubject.jpg";

import CHUONGTRINHGIANGDAY01 from "../image/KhoaHoc/CHUONGTRINHGIANGDAY-01.png";
import CHUONGTRINHGIANGDAY02 from "../image/KhoaHoc/CHUONGTRINHGIANGDAY-02.png";
import CHUONGTRINHGIANGDAY03 from "../image/KhoaHoc/CHUONGTRINHGIANGDAY-03.png";
import CHUONGTRINHGIANGDAY04 from "../image/KhoaHoc/CHUONGTRINHGIANGDAY-04.png";
import CHUONGTRINHGIANGDAY05 from "../image/KhoaHoc/CHUONGTRINHGIANGDAY-05.png";
import CHUONGTRINHGIANGDAY06 from "../image/KhoaHoc/CHUONGTRINHGIANGDAY-06.png";
import CHUONGTRINHGIANGDAY07 from "../image/KhoaHoc/CHUONGTRINHGIANGDAY-07.png";
import CHUONGTRINHGIANGDAY08 from "../image/KhoaHoc/CHUONGTRINHGIANGDAY-08.png";
import CHUONGTRINHGIANGDAY09 from "../image/KhoaHoc/CHUONGTRINHGIANGDAY-09.png";
import CHUONGTRINHGIANGDAY10 from "../image/KhoaHoc/CHUONGTRINHGIANGDAY-10.png";
import CHUONGTRINHGIANGDAY11 from "../image/KhoaHoc/CHUONGTRINHGIANGDAY-11.png";
import React from "react";
import { Collapse } from "react-collapse";
import "react-datepicker/dist/react-datepicker.css";
import Rating from "react-rating";
import {
  Button,
  Dropdown,
  Image,
  Input,
  Modal,
  Table,
  Label,
} from "semantic-ui-react";
import * as XLSX from "xlsx";
import "../App.css";
import CuuCungPhiTinh from "../components/CuuCungPhiTinh";
import HeaderHomePage from "../components/HeaderHomePage";
import HourStar from "../components/HourStar";
import PhiTinhDetail from "../components/PhiTinhDetail";
import PhiTinhDetailNew from "../components/PhiTinhDetailNew";
import SaoThan from "../components/SaoThan";
import TableQue from "../components/TableQue";
import Const from "../Const";
import jwtDecode from "jwt-decode";
import {
  convertHour,
  DayToCanChi,
  getNamAp,
  getNhiThapKienTru,
  getQue,
  getQueLast,
  HourToCanChi,
  kiengKy,
  MonthToCanChi,
  ngayGioHoangDao,
  phiTinhThang,
  thapNhiBatTu,
  YearToCan,
  YearToChi,
  getNumberChi,
} from "../convert.js";
import globalUtils from "../globalUntils.js";
import HaDo from "../HaDo.png";
import arrow from "../image/arrow2.png";
import arrow2 from "../image/arrow3.png";
import five from "../image/five.png";
import LaBan from "../image/laban.png";
import LaBanV2 from "../image/laban_v2.png";
import nguHoang from "../image/nguHoangCordi.png";
import tamSat from "../image/tamSat.png";
import Logo from "../logo.png";
import { deleteDataSave, getData, SaveData } from "../db/db";
import Editor from "../components/Editor";
import Truncate from "react-truncate-html";
import { remove } from "jszip";

const optionDropdown = _.map(Const.QueSortConGiap, (state, index) => ({
  key: `${state.stt}-${index}`,
  text: `${state.queViet} ${state.huyenKhongNguHanh}/${state.huyenKhongQuaiVan}`,
  value: index,
}));
const mdfive = md5("HuyenKhongTamNguyenFengShuiWebSiteHitekTuongMinh");
const msADay = 86400000;
class TrachNhat extends React.Component {
  constructor (props) {
    super(props);
    this.kyMonDonGiapThangNam = [];
    this.excelLichVanNien = [];
    this.tietKhiDate = [];
    this.dataNhiTuTietKhi = [];
    this.tietKhiInYear = [];
    this.wrapperRef = React.createRef();
    this.dataAmDon = [];
    this.dataDuongDon = [];
    this.dataAmDon_new = [];
    this.dataDuongDon_new = [];
    this.kyMonDonGiapNgay = [];
    this.namSinhChange = _.debounce(this.namSinhChange.bind(this), 1000);
    this.state = {
      //headerlogin
      starMonth: null,
      exprireDate: "",
      userName: "",
      isHistory: false,
      modalPressSearchCode: false,
      modalPressCode: false,
      modalErrorCode: false,
      modalUsedCode: false,
      modalSuccessCode: false,
      modalEmptyCode: false,
      modalSaveData: false,
      modalHistory: false,
      modalDeleteData: false,
      modalNote: false,

      //other
      openKyMonNgayGio: false,
      openKyMonChuyenCung: false,
      openKyMonMaoSon: false,
      openKyMonThangNam: false,
      date: new Date(),
      ngayKyAmLich: "",
      ngayKyTietKhi: "",
      dateShowBellowSolarDate: null,
      searchSelectMonth: null,
      result1080cucHour: null,
      result1080cucHour_new: null,
      result1080cucHourMaoSon: null,
      result1080cucDate: null,
      result1080cucMonth: null,
      result1080cucYear: null,
      searchSelectYear: null,
      canChiDay: null,
      dateLunar: null,
      realDatePhiTinh: null,
      dateTietKhi: null,
      characterSearch: null,
      huongTamSatHour: "",
      huongTamSatDay: "",
      huongTamSatMonth: "",
      huongTamSatYear: "",
      queNam: null,
      kiengkytrongngay: null,
      ngayHoangDao: null,
      nhiThapKienTru: null,
      isActive: false,
      keyWordSearch: null,
      searchParam: null,
      namSinhCanChi: null,
      huongNguHoangYear: null,
      huongNguHoangMonth: null,
      starDay: null,
      taiThan: null,
      searchResult: null,
      hyThan: null,
      thaiDuongThaiAm: null,
      isLoading: false,
      lichVanNien: [],
      modalImage: false,
      star: null,
      chiMonthLunar: null,
      toaCanChi: null,
      napAmNgay: {},
      canDay: "",
      canMonth: "",
      canYear: "",
      canHour: "",
      chiDay: "",
      chiMonth: "",
      modalSearch: false,
      modalLaBan: false,
      toaNumber: 0,
      namSinhNumber: 0,
      momentYear: null,
      momentMonth: null,
      momentDate: null,
      momentHour: new Date().getHours(),
      isSauDongChi: null,
      chiHour: "",
      dateData: Const.initDateData,
      hourDate: Const.initHourDate,
      dataRender: [],
      userInfo: null,
      namSinhReal: null,
      toaSinhReal: null,
      dateSave: null,
      dataEditor: null,
      hoTenUser: "",
    };
  }

  handleSaveData = () => {
    const getObj = localStorage.getItem(mdfive);
    const dataSavedStorage = JSON.parse(getObj);
    const newObj = {
      userName: dataSavedStorage.userName,
      name: dataSavedStorage.name,
      hoTen: this.state.hoTenUser,
      email: dataSavedStorage.email,
      namSinhNumber: this.state.namSinhNumber,
      namSinhReal: this.state.namSinhReal,
      toaSinhReal: this.state.toaSinhReal || "Giáp Tý 1/1",
      toaNumber: this.state.toaNumber,
      note: this.state.dataEditor,
      date: this.state.dateSave ? this.state.dateSave : new Date(),
      ...this.state.dateData,
    };
    SaveData(newObj, (isSuccess) => {
      if (isSuccess) {
        this.setState({ modalSaveData: true });
      }
    });
    sessionStorage.setItem("saveData", JSON.stringify(newObj));
  };
  
  handleSaveNote = () => {
    this.setState({ modalNote: false });
  };

  async handleKyMon() {
    const { date } = this.state;

    let ngayAmLich = lunar.solar2Lunar(
      date.getDate(),
      date.getMonth() + 1,
      date.getFullYear()
    );
    //date ngayAmLich[0]
    //date ngayAmLich[1]
    const tempTime = new Date(
      ngayAmLich[2],
      ngayAmLich[1] - 1,
      ngayAmLich[0],
      date.getHours(),
      date.getMinutes()
    );

    // lấy lịch tiết khí của cả 1 năm
    this.getTietKhiAllYear(tempTime);
    // lấy đầy đủ tên tiết khí của 1 năm
    this.get24TietKhiOfYear(tempTime);
    // điều chỉnh lịch dương thành lịch tiết khí(chủ yếu là tháng)
    let dateTietKhi = this.getCorrectTietKhi(tempTime);

    const chiYear = this.state.dateData[3].diaChi

    const canChiHour = this.state.chiHour;
    const chiHour = canChiHour[1];
    const chiHourNumber = await getNumberChi(chiHour);
    const chiYearNumber = await getNumberChi(chiYear);
    const sumChiNumber =
      (chiHourNumber + ngayAmLich[0] + ngayAmLich[1] + chiYearNumber) % 9; //index
    const ngay = this.state.date.getDate();
    const thang = this.state.date.getMonth() + 1;
    let amDonOrDuongDonGio = "A";

    // tu 21/12 den 21/6 la duong
    if ((thang === 12 && ngay >= 21) || (thang < 6) || (thang === 6 && ngay <= 21)) {
      // AM DON MAO SON
      amDonOrDuongDonGio = 1;
    }

    const dataCucMaoSon = this.get1080cucMaoSon(
      amDonOrDuongDonGio,
      canChiHour,
      sumChiNumber
    );
    this.setState({
      result1080cucHourMaoSon: dataCucMaoSon,
    });
  }

  async handleGetData() {
    const getObj = localStorage.getItem(mdfive);
    const dataSavedStorage = JSON.parse(getObj);
    const email = dataSavedStorage?.email;
    const data = await getData(email);
    this.setState({
      modalHistory: true,
      dataRender: data,
      userInfo: dataSavedStorage,
    });
  }

  async handleDelete(id) {
    const getObj = localStorage.getItem(mdfive);
    const dataSavedStorage = JSON.parse(getObj);
    const email = dataSavedStorage?.email;
    await deleteDataSave(id, async (isSuccess) => {
      if (isSuccess) {
        this.setState({ modalDeleteData: true }, () => {
          setTimeout(() => {
            this.handleGetData();
          }, 500);
        });
      }
    });
  }

  async handleResult(item) {
    let newDate;
    if (item.date) {
      newDate = new Date(
        item.date.seconds * 1000 + item.date.nanoseconds / 1000000
      );
    } else {
      newDate = new Date();
    }

    this.setState(
      {
        modalHistory: false,
        date: newDate,
        toaNumber: item.toaNumber,
        namSinhNumber: item.namSinhNumber,
        namSinhReal: item.namSinhReal ? item.namSinhReal : "",
        toaSinhReal: item.toaSinhReal,
        dataEditor: item.note,
        hoTenUser: item.hoTen,
        dateData: [
          item.hour,
          item.day,
          item.month,
          item.year,
          item.toa,
          item.namSinh,
        ],
      },
      () => {
        setTimeout(() => {
          this.onChangeData();
        }, 500);
      }
    );
  }

  formatDate(date) {
    if (date) {
      let minutes = date.getMinutes();
      let hour = date.getHours();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();

      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (hour < 10) {
        hour = "0" + hour;
      }
      if (day < 10) {
        day = "0" + day;
      }
      if (month < 10) {
        month = "0" + month;
      }
      const newDate =
        day + "/" + month + "/" + year + "," + " " + hour + ":" + minutes;
      return newDate;
    } else {
      return "Không có";
    }
  }

  onSendRedeemCode = (Code) => {
    const getObj = localStorage.getItem(mdfive);
    const emailSavedStorage = JSON.parse(getObj);

    let timeStart = new Date();
    //check trình duyệt
    // var nVer = navigator.appVersion;
    var nAgt = navigator.userAgent;
    var browserName = navigator.appName;
    var fullVersion = "" + parseFloat(navigator.appVersion);
    var majorVersion = parseInt(navigator.appVersion, 10);
    var nameOffset, verOffset, ix;
    // In Opera, the true version is after "Opera" or after "Version"
    if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
      browserName = "Opera";
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
      browserName = "Microsoft Internet Explorer";
    }
    // In Chrome, the true version is after "Chrome"
    else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
      browserName = "Chrome";
    }
    // In Safari, the true version is after "Safari" or after "Version"
    else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
      browserName = "Safari";
    }
    // In Firefox, the true version is after "Firefox"
    else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
      browserName = "Firefox";
    }
    // In most other browsers, "name/version" is at the end of userAgent
    else if (
      (nameOffset = nAgt.lastIndexOf(" ") + 1) <
      (verOffset = nAgt.lastIndexOf("/"))
    ) {
      browserName = nAgt.substring(nameOffset, verOffset);
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(";")) !== -1)
      fullVersion = fullVersion.substring(0, ix);
    if ((ix = fullVersion.indexOf(" ")) !== -1)
      fullVersion = fullVersion.substring(0, ix);

    majorVersion = parseInt("" + fullVersion, 10);
    if (isNaN(majorVersion)) {
      fullVersion = "" + parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion, 10);
    }

    var database = firebase.app("database").firestore();
    var data = database.collection("redeem_code_HuyenKhong").doc(Code);
    let isSearch;
    var dataUser = database
      .collection("User_HuyenKhong")
      .doc(emailSavedStorage.email);
    data.get().then((doc) => {
      if (doc.exists) {
        dataUser.get().then((docUser) => {
          if (docUser.exists) {
            isSearch = docUser.data().activeSearch
              ? docUser.data().activeSearch
              : false;
            if (!doc.data()?.isActive) {
              if (doc.data()?.level === 1) {
                timeStart.setMonth(timeStart.getMonth() + 3);
                data.update({
                  ...doc.data(),
                  isActive: true,
                  enableHistory: true,
                  enableChuyenCung: doc.data().enableChuyenCung
                    ? doc.data().enableChuyenCung
                    : docUser.data().isActiveChuyenCung
                      ? docUser.data().isActiveChuyenCung
                      : false,
                  enableAmBanMaoSon: doc.data().enableAmBanMaoSon
                    ? doc.data().enableAmBanMaoSon
                    : docUser.data().isActiveChuyenCung
                      ? docUser.data().isActiveChuyenCung
                      : false,
                });
                dataUser.set({
                  ...doc.data(),
                  activeSearch: isSearch,
                  name: this.state.userName,
                  isActive: true,
                  isHistory: true,
                  isActiveChuyenCung: doc.data().enableChuyenCung
                    ? doc.data().enableChuyenCung
                    : docUser.data().isActiveChuyenCung
                      ? docUser.data().isActiveChuyenCung
                      : false,
                  isActiveAmBanMaoSon: doc.data().enableAmBanMaoSon
                    ? doc.data().enableAmBanMaoSon
                    : docUser.data().isActiveChuyenCung
                      ? docUser.data().isActiveChuyenCung
                      : false,
                  level: 1,
                  activeOnBrower: browserName,
                  exprireDate: new Date(timeStart.toDateString()),
                  activeDate: new Date(),
                });
              } else if (doc.data()?.level === 2) {
                timeStart.setMonth(timeStart.getMonth() + 6);
                data.update({
                  ...doc.data(),
                  isActive: true,
                  enableHistory: true,
                  enableChuyenCung: doc.data().enableChuyenCung
                    ? doc.data().enableChuyenCung
                    : docUser.data().isActiveChuyenCung
                      ? docUser.data().isActiveChuyenCung
                      : false,
                  enableAmBanMaoSon: doc.data().enableAmBanMaoSon
                    ? doc.data().enableAmBanMaoSon
                    : docUser.data().isActiveChuyenCung
                      ? docUser.data().isActiveChuyenCung
                      : false,
                });
                dataUser.set({
                  ...doc.data(),
                  name: this.state.userName,
                  isActive: true,
                  isHistory: true,
                  activeSearch: isSearch,
                  isActiveChuyenCung: doc.data().enableChuyenCung
                    ? doc.data().enableChuyenCung
                    : docUser.data().isActiveChuyenCung
                      ? docUser.data().isActiveChuyenCung
                      : false,
                  isActiveAmBanMaoSon: doc.data().enableAmBanMaoSon
                    ? doc.data().enableAmBanMaoSon
                    : docUser.data().isActiveChuyenCung
                      ? docUser.data().isActiveChuyenCung
                      : false,
                  level: 2,
                  activeOnBrower: browserName,
                  exprireDate: new Date(timeStart.toDateString()),
                  activeDate: new Date(),
                });
              } else if (doc.data()?.level === 3) {
                timeStart.setMonth(timeStart.getMonth() + 12);
                data.update({
                  ...doc.data(),
                  isActive: true,
                  enableHistory: true,
                  enableChuyenCung: doc.data().enableChuyenCung
                    ? doc.data().enableChuyenCung
                    : docUser.data().isActiveChuyenCung
                      ? docUser.data().isActiveChuyenCung
                      : false,
                  enableAmBanMaoSon: doc.data().enableAmBanMaoSon
                    ? doc.data().enableAmBanMaoSon
                    : docUser.data().isActiveChuyenCung
                      ? docUser.data().isActiveChuyenCung
                      : false,
                });
                dataUser.set({
                  ...doc.data(),
                  name: this.state.userName,
                  isActive: true,
                  isHistory: true,
                  isActiveChuyenCung: doc.data().enableChuyenCung
                    ? doc.data().enableChuyenCung
                    : docUser.data().isActiveChuyenCung
                      ? docUser.data().isActiveChuyenCung
                      : false,
                  isActiveAmBanMaoSon: doc.data().enableAmBanMaoSon
                    ? doc.data().enableAmBanMaoSon
                    : docUser.data().isActiveChuyenCung
                      ? docUser.data().isActiveChuyenCung
                      : false,
                  activeSearch: isSearch,
                  level: 3,
                  activeOnBrower: browserName,
                  exprireDate: new Date(timeStart.toDateString()),
                  activeDate: new Date(),
                });
              } else if (doc.data()?.level === 4) {
                timeStart.setFullYear(timeStart.getFullYear() + 3);
                data.update({
                  ...doc.data(),
                  isActive: true,
                  enableHistory: true,
                  enableChuyenCung: doc.data().enableChuyenCung
                    ? doc.data().enableChuyenCung
                    : docUser.data().isActiveChuyenCung
                      ? docUser.data().isActiveChuyenCung
                      : false,
                  enableAmBanMaoSon: doc.data().enableAmBanMaoSon
                    ? doc.data().enableAmBanMaoSon
                    : docUser.data().isActiveChuyenCung
                      ? docUser.data().isActiveChuyenCung
                      : false,
                });
                dataUser.set({
                  ...doc.data(),
                  name: this.state.userName,
                  isActive: true,
                  isHistory: true,
                  isActiveChuyenCung: doc.data().enableChuyenCung
                    ? doc.data().enableChuyenCung
                    : docUser.data().isActiveChuyenCung
                      ? docUser.data().isActiveChuyenCung
                      : false,
                  isActiveAmBanMaoSon: doc.data().enableAmBanMaoSon
                    ? doc.data().enableAmBanMaoSon
                    : docUser.data().isActiveChuyenCung
                      ? docUser.data().isActiveChuyenCung
                      : false,
                  activeSearch: isSearch,
                  level: 4,
                  activeOnBrower: browserName,
                  exprireDate: new Date(timeStart.toDateString()),
                  activeDate: new Date(),
                });
              } else if (doc.data()?.level === 5) {
                timeStart.setFullYear(timeStart.getFullYear() + 100);
                data.update({
                  ...doc.data(),
                  isActive: true,
                  enableHistory: true,
                  enableChuyenCung: doc.data().enableChuyenCung
                    ? doc.data().enableChuyenCung
                    : docUser.data().isActiveChuyenCung
                      ? docUser.data().isActiveChuyenCung
                      : false,
                  enableAmBanMaoSon: doc.data().enableAmBanMaoSon
                    ? doc.data().enableAmBanMaoSon
                    : docUser.data().isActiveChuyenCung
                      ? docUser.data().isActiveChuyenCung
                      : false,
                });
                dataUser.set({
                  ...doc.data(),
                  name: this.state.userName,
                  isActive: true,
                  isHistory: true,
                  isActiveChuyenCung: doc.data().enableChuyenCung
                    ? doc.data().enableChuyenCung
                    : docUser.data().isActiveChuyenCung
                      ? docUser.data().isActiveChuyenCung
                      : false,
                  isActiveAmBanMaoSon: doc.data().enableAmBanMaoSon
                    ? doc.data().enableAmBanMaoSon
                    : docUser.data().isActiveChuyenCung
                      ? docUser.data().isActiveChuyenCung
                      : false,
                  activeSearch: isSearch,
                  level: 5,
                  activeOnBrower: browserName,
                  exprireDate: new Date(timeStart.toDateString()),
                  activeDate: new Date(),
                });
              }

              const dataStorage = {
                userName: this.state.userName,
                email: emailSavedStorage.email,
                isActive: true,
                isHistory: true,
                isActiveChuyenCung: doc.data().enableChuyenCung
                  ? doc.data().enableChuyenCung
                  : docUser.data().isActiveChuyenCung
                    ? docUser.data().isActiveChuyenCung
                    : false,
                isActiveAmBanMaoSon: doc.data().enableAmBanMaoSon
                  ? doc.data().enableAmBanMaoSon
                  : docUser.data().isActiveChuyenCung
                    ? docUser.data().isActiveChuyenCung
                    : false,
                activeSearch: isSearch,
                exprireDate: timeStart,
              };
              const jsonUser = JSON.stringify(dataStorage);

              this.setState({ modalSuccessCode: true }, () =>
                localStorage.setItem(mdfive, jsonUser)
              );
            } else {
              this.setState({ modalUsedCode: true });
            }
          }
        });
      } else {
        this.setState({ modalErrorCode: true });
      }
    });
  };
  onSendRedeemSearchCode = (Code) => {
    const getObj = localStorage.getItem(mdfive);
    const emailSavedStorage = JSON.parse(getObj);

    if (!emailSavedStorage) {
      return;
    }

    let timeStart = new Date();
    //check trình duyệt

    var database = firebase.app("database").firestore();
    var data = database.collection("code_search_HuyenKhong").doc(Code);

    var dataUser = database
      .collection("User_HuyenKhong")
      .doc(emailSavedStorage.email);
    data.get().then((doc) => {
      if (doc.exists) {
        if (doc.data()?.isActive === false) {
          // if (doc.data()?.level === 1) {
          //   timeStart.setMonth(timeStart.getMonth() + 3);
          data.update({
            ...doc.data(),
            isActive: true,
          });
          dataUser.get().then((userData) => {
            dataUser.set({
              ...userData.data(),
              activeSearch: true,
            });
          });

          const dataStorage = {
            ...emailSavedStorage,
            userName: this.state.userName,
            email: emailSavedStorage.email,
            isActive: doc.data()?.isActive,
            activeSearch: true,
          };
          const jsonUser = JSON.stringify(dataStorage);

          this.setState({ modalSuccessCode: true }, () =>
            localStorage.setItem(mdfive, jsonUser)
          );
        } else {
          this.setState({ modalUsedCode: true });
        }
      } else {
        this.setState({ modalErrorCode: true });
      }
    });
  };
  async checkLogin() {
    try {
      const emailSaveStorage = localStorage.getItem(mdfive);
      const userData = JSON.parse(emailSaveStorage);
      // nếu có lưu local thì là đã đăng nhập
      // nếu chưa lưu thì là chưa đăng nhập
      if (userData.isCustomer) {
        // đây là phòng trường hợp user sử dụng lại trường cũ, nếu sử dụng trường cũ thì clear storage và logout luôn để sử dụng trường mới
        localStorage.clear();
        this.logout();
      } else {
        var database = await firebase.app("database").firestore();

        if (userData?.email) {
          var data = database
            .collection("User_HuyenKhong")
            .doc(userData?.email);
          let userName = "";
          let exprireDate = true;
          let isActive = false;
          let isHistory = false;
          data.get()
          .then((doc) => {
            if (doc.exists) {
              globalUtils.isLogin = true;
              isHistory = doc.data().isHistory;
              userName = doc.data().name;
              exprireDate = new Date(doc.data().exprireDate.seconds * 1000);
              const toDay = new Date();
              if (exprireDate < toDay) {
                exprireDate = null;
                data
                  .update({
                    isActive: false,
                  })
                  .then((valueSuccess) => { })
                  .catch((error) => {
                    console.error("Error updating document: ", error);
                  });
                isActive = false;
                const dataStorage = {
                  userName,
                  email: userData.email,
                  ...doc.data(),
                  isActive,
                  isHistory: doc.data().isHistory
                    ? doc.data().isHistory
                    : false,
                  exprireDate,
                };
                const jsonUser = JSON.stringify(dataStorage);
                localStorage.setItem(mdfive, jsonUser);
              } else if (doc.data().isActive) {
                isActive = doc.data().isActive;
                const dataStorage = {
                  userName,
                  email: userData.email,
                  ...doc.data(),
                  isActive,
                  isHistory,
                  exprireDate,
                };
                const jsonUser = JSON.stringify(dataStorage);
                localStorage.setItem(mdfive, jsonUser);
              }
            } else {
              const dataStorage = {
                ...doc.data(),
              };
              const jsonUser = JSON.stringify(dataStorage);
              localStorage.setItem(mdfive, jsonUser);
            }
            this.setState({ userName, isActive, exprireDate, isHistory });
          }).catch(err=>console.log("cuong xem err",err));
        }
      }
    } catch (error) {
      localStorage.clear();
    }
  }
  checkExist(doc, data, database, response) {
    var expireDate = new Date();

    return new Promise(async (resolve, reject) => {

      if (doc.exists) {
        globalUtils.isLogin = true;
        //giải quyết vụ 5 ngày là customer, sau 5 ngày không là gì cả

        expireDate = new Date(doc.data().exprireDate.seconds * 1000);

        const toDay = new Date();

        if (expireDate < toDay && doc.data().isActive) {
          data
            .update({
              isActive: false,
            })
            .then((valueSuccess) => { })
            .catch((error) => {
              // The document probably doesn't exist.
              console.error("Error updating document: ", error);
            });
        }
      } else {
        // trường hợp đăng nhập lần đầu thì ghi vô data base
        expireDate.setDate(expireDate.getDate() + 5);

        await database.collection("User_HuyenKhong").doc(response.email).set({
          email: response.email,
          name: response.name,
          isActive: true,
          loginDate: new Date(),
          exprireDate: expireDate,
        });
      }
      const dataStorage = {
        userName: response.name,
        email: response.email,
        ...doc.data(),
        isActive: true,
        exprireDate: expireDate,
      };
      const jsonUser = JSON.stringify(dataStorage);
      localStorage.setItem(mdfive, jsonUser);
      resolve();
    });
  }
  login = (response) => {
    this.setState({ isLoading: true });
    const database = firebase.app("database").firestore();

    if (response.credential) {
      const dataDecode = jwtDecode(response.credential);
      const user = {
        name: dataDecode.name,
        email: dataDecode.email,
      };
      globalUtils.isLogin = true;
      this.setState({ userName: user.name });

      // var newDoc = database.collection('User_HuyenKhong').doc();
      var exprireDate = new Date();
      var data = database.collection("User_HuyenKhong").doc(user.email);

      data.get().then(async (doc) => {
        //check tồn tại
        await this.checkExist(doc, data, database, user)
          .then(() => {
            this.setState({ isLoading: false });

            setTimeout(() => {
              window.location.reload(false);
            }, 500);
          })
          .catch((err) => console.log(err));
      });
    }
  };
  logout = () => {
    const google = (window.google = window.google ? window.google : {});
    google.accounts.id.disableAutoSelect();
    this.setState({ isLoading: true });
    localStorage.clear();
    window.location.reload();
  };
  renderModalErrorPressCode = (nameModal, actionsCloseModal, content) => {
    return (
      <Modal open={nameModal} size="small">
        <Modal.Content>
          <p>{content}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Đóng" onClick={actionsCloseModal} />
        </Modal.Actions>
      </Modal>
    );
  };
  renderModalNote = (nameModal, actionsCloseModal, content) => {
    return (
      <Modal open={nameModal} size="large" style={{ height: "80%" }}>
        <Modal.Content style={{ height: "80%" }}>
          <h2>Ghi chú</h2>
          <Editor
            value={(data) => this.setState({ dataEditor: data })}
            dataContent={this.state.dataEditor}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button primary content="Lưu" onClick={actionsCloseModal} />
        </Modal.Actions>
      </Modal>
    );
  };

  renderHistoryModal = (nameModal, actionsCloseModal) => {
    return (
      <Modal open={nameModal} size="large" style={{ height: "80%" }}>
        <Modal.Content style={{ height: "80%", overflow: "scroll" }}>
          <h3>Lịch sử dữ liệu:</h3>
          {this.state.dataRender.length > 0 ? (
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Họ tên</Table.HeaderCell>
                  <Table.HeaderCell>Ngày giờ chọn</Table.HeaderCell>
                  <Table.HeaderCell>Tọa</Table.HeaderCell>
                  <Table.HeaderCell>Năm sinh</Table.HeaderCell>
                  <Table.HeaderCell>Ghi chú</Table.HeaderCell>
                  <Table.HeaderCell>Chức năng</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {this.state.dataRender.length > 0 ? (
                  this.state.dataRender.map((item) => (
                    <Table.Row>
                      <Table.Cell>
                        {item?.hoTen ? item?.hoTen : "Không có"}
                      </Table.Cell>
                      <Table.Cell>{this.formatDate(item?.newDate)}</Table.Cell>
                      <Table.Cell>
                        {item?.toaSinhReal ? item?.toaSinhReal : "Không có"}
                      </Table.Cell>
                      <Table.Cell>
                        {item?.namSinhReal
                          ? item?.namSinhReal
                          : "Không có năm sinh"}
                      </Table.Cell>
                      <Table.Cell>
                        <Truncate
                          lines={30}
                          dangerouslySetInnerHTML={{
                            __html: item.note,
                          }}
                        />
                      </Table.Cell>
                      <Table.Cell style={{ minWidth: "200px" }}>
                        <Button
                          onClick={() => this.handleResult(item)}
                          color="blue"
                        >
                          Xem
                        </Button>
                        <Button
                          onClick={() => this.handleDelete(item?.id)}
                          color="red"
                        >
                          Xóa
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  ))
                ) : (
                  <h3>Không có dữ liệu</h3>
                )}
              </Table.Body>
            </Table>
          ) : (
            <div style={{ textAlign: "center", fontSize: "28px" }}>
              <b>Không có dữ liệu</b>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button content="Đóng" onClick={actionsCloseModal} />
        </Modal.Actions>
      </Modal>
    );
  };

  renderShould(header, content, colorShould) {
    const devideComma = content?.split(",");
    return (
      <td>
        <table className="should">
          <tbody>
            <tr>
              <td
                style={{
                  backgroundColor: colorShould,
                  color: "white",
                  padding: "10px",
                  fontWeight: "bolder",
                  fontSize: "120%",
                }}
              >
                {header}
              </td>
            </tr>
            <tr>
              <td style={{ backgroundColor: "white", color: colorShould }}>
                {/* {content} */}
                <div className="shouldContent">
                  {devideComma?.length > 0
                    ? devideComma.map((value) => {
                      return <p key={`devideComma${value}`}>{value}</p>;
                    })
                    : null}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    );
  }
  shouldDuplicateQue(thienChi, diaChi) {
    if (thienChi === Const.Can.Giap.name && diaChi === Const.Chi.Ngo.name)
      return true;
    if (thienChi === Const.Can.Giap.name && diaChi === Const.Chi.Ty.name)
      return true;
    if (thienChi === Const.Can.Canh.name && diaChi === Const.Chi.Than.name)
      return true;
    if (thienChi === Const.Can.Canh.name && diaChi === Const.Chi.Dan.name)
      return true;
    return false;
  }

  getDuplicateQue(initialQque, thienChi, diaChi) {
    let que = getQueLast(thienChi, diaChi);
    if (!initialQque || !que) {
      return null;
    }

    if (
      initialQque.huyenKhongNguHanh === que.huyenKhongNguHanh &&
      initialQque.huyenKhongQuaiVan === que.huyenKhongQuaiVan
    ) {
      que = getQue(thienChi, diaChi);
    }
    return que;
  }

  renderTimeInfo(header, thienChi, diaChi, qua, que, hoangDao, phiTinhGio) {
    const shouldDuplicateQue =
      this.shouldDuplicateQue(thienChi, diaChi) && header === "Năm sinh";
    const duplicateQue = this.getDuplicateQue(que, thienChi, diaChi);
    let shouldReverseQue = false;
    if (shouldDuplicateQue) {
      const lastQue = getQueLast(thienChi, diaChi);
      shouldReverseQue =
        lastQue.huyenKhongNguHanh === que.huyenKhongNguHanh &&
        lastQue.huyenKhongQuaiVan === que.huyenKhongQuaiVan;
    }
    return (
      <td key={`timeInfo-${header}`} width={header === "Tọa" ? "150px" : null}>
        <table className="timeInfo">
          <tbody>
            <tr>
              <td
                style={{
                  padding: "5px",
                  fontWeight: "bolder",
                  color: "#1667AF",
                }}
              >
                {header}
              </td>
            </tr>
            {header === "Năm sinh" ? (
              <tr>
                <td>
                  <Input
                    disabled={!this.state.isActive}
                    id="namSinhGiaChu"
                    size="small"
                    placeholder="Nhập Năm Sinh..."
                    type="number"
                    value={this.state.namSinhReal}
                    onChange={(event, data) => {
                      this.setState({ namSinhReal: data.value });
                      this.namSinhChange(data.value);
                    }}
                  />
                </td>
              </tr>
            ) : header === "Ngày" ? (
              <tr height="35px"></tr>
            ) : header === "Tháng" ? (
              <tr height="35px"></tr>
            ) : header === "Năm" ? (
              <tr height="35px"></tr>
            ) : header === "Tọa" ? (
              <tr height="35px">
                <td>
                  <Dropdown
                    disabled={!this.state.isActive}
                    onChange={(context, data) => {
                      this.setState({
                        toaNumber: data.value,
                        toaSinhReal: context.target.textContent,
                      });
                      setTimeout(() => {
                        this.onChangeData();
                      }, 200);
                    }}
                    placeholder="Giáp Tý 1/1"
                    search
                    fluid
                    value={this.state.toaNumber}
                    selection
                    options={optionDropdown}
                  />
                </td>
              </tr>
            ) : (
              <tr height="35px"></tr>
            )}
            <tr>
              <td
                style={{
                  height: "0px",
                  fontWeight: "bolder",
                  backgroundColor: "#fff",
                }}
              ></td>
            </tr>
            <tr>
              <td width="100%" style={{}}>
                <table width="100%" style={{ borderSpacing: "0px" }}>
                  <tbody>
                    <tr>
                      <td width="50%">
                        <button
                          disabled={!this.state.isActive}
                          size="mini"
                          icon="true"
                          compact="true"
                          onClick={() => {
                            if (header === "Ngày") {
                              this.onClickBackDate();
                            } else if (header === "Tháng") {
                              this.onClickBackMonth();
                            } else if (header === "Năm") {
                              this.onClickBackYear();
                            } else if (header === "Giờ") {
                              this.onClickBackHour();
                            } else if (header === "Tọa") {
                              this.onClickBackToaNNamSinh(
                                this.state.toaNumber,
                                "Tọa"
                              );
                            } else if (header === "Năm sinh") {
                              this.onClickBackToaNNamSinh(
                                this.state.namSinhNumber,
                                "Năm sinh"
                              );
                            }
                          }}
                          ref={(e) => `${header}Button`}
                          type="button"
                          style={{
                            backgroundColor: "#F05828",
                            width: "100%",
                            borderTopLeftRadius: "7px",
                            height: "30px",
                            borderColor: "#fff",
                            borderWidth: "1px",
                          }}
                        >
                          <i
                            color="#fff"
                            className="fa fa-caret-left fa-2x"
                            aria-hidden="true"
                            style={{ color: "#fff" }}
                          ></i>
                        </button>
                      </td>
                      <td width="50%">
                        <button
                          disabled={!this.state.isActive}
                          size="mini"
                          icon="true"
                          compact="true"
                          onClick={() => {
                            if (header === "Ngày") {
                              this.onClickNextDate();
                            } else if (header === "Tháng") {
                              this.onClickNextMonth();
                            } else if (header === "Năm") {
                              this.onClickNextYear();
                            } else if (header === "Giờ") {
                              this.onClickNextHour();
                            } else if (header === "Tọa") {
                              this.onClickNextToaNNamSinh(
                                this.state.toaNumber,
                                "Tọa"
                              );
                            } else if (header === "Năm sinh") {
                              this.onClickNextToaNNamSinh(
                                this.state.namSinhNumber,
                                "Năm Sinh"
                              );
                            }
                          }}
                          type="button"
                          style={{
                            backgroundColor: "#F05828",
                            width: "100%",
                            borderColor: "#fff",
                            borderWidth: "1px",
                            borderTopRightRadius: "7px",
                            height: "30px",
                          }}
                        >
                          <i
                            color="#fff"
                            style={{ color: "#fff" }}
                            className="fa fa-caret-right fa-2x"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

            <tr height="25px" style={{}}>
              <td
                width="16.5%"
                style={{
                  backgroundColor: "#1667AF",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                {thienChi}
              </td>
            </tr>
            {!hoangDao ? (
              <tr height="5px" style={{}}>
                <td
                  width="16.5%"
                  style={{
                    backgroundColor: "#fff",
                    color: "white",
                    fontWeight: "bold",
                  }}
                ></td>
              </tr>
            ) : null}

            <tr height="25px" style={{}}>
              <td
                width="16.5%"
                style={{
                  backgroundColor: "#1667AF",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                {diaChi}
              </td>
            </tr>

            <tr>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <TableQue
                          data={shouldReverseQue ? duplicateQue : que}
                        />
                      </td>
                      {header === "Năm sinh" && shouldDuplicateQue ? (
                        <td>
                          <TableQue
                            data={shouldReverseQue ? que : duplicateQue}
                          />
                        </td>
                      ) : null}
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr className="to-hide-print" height="10px"></tr>
            <tr>
              <td>
                <table width="100%">
                  <tbody>
                    <tr>
                      <td id="borderTable" style={{}}>
                        {shouldReverseQue ? duplicateQue.tenque : qua}
                      </td>
                      {header === "Năm sinh" && shouldDuplicateQue ? (
                        <td id="borderTable" style={{}}>
                          {shouldReverseQue ? qua : duplicateQue.tenque}
                        </td>
                      ) : null}
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr className="to-hide-print" height="10px"></tr>

            {hoangDao ? (
              <tr height="50px" style={{}}>
                <td
                  width="16.5%"
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    backgroundColor: "#AA3830",
                  }}
                >
                  {hoangDao}
                </td>
              </tr>
            ) : null}
            {phiTinhGio ? (
              <tr height="50px" style={{}}>
                <img src={HaDo} alt="logo" width="16.5%" height="100px" />
              </tr>
            ) : null}
            {phiTinhGio ? (
              <tr height="50px" style={{}}>
                <td
                  width="16.5%"
                  style={{
                    backgroundColor: "#fd940a",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  {phiTinhGio}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </td>
    );
  }
  handleChange = (date) => {
    this.setState(
      {
        date: date,
        momentYear: date.getYear(),
        momentDate: date.getDate(),
        momentMonth: date.getMonth(),
      },
      () => {
        this.onChangeData();
      }
    );
    // setTimeout(() => {

    // }, 400);
  };
  handleChangeSearch = (date) => {
    this.setState(
      {
        date: date,
        momentYear: date.getYear(),
        momentDate: date.getDate(),
        momentMonth: date.getMonth() + 1,
      },
      () => {
        this.onChangeData();
      }
    );
    setTimeout(() => { }, 400);
  };

  getDayStarOfADate(dateTime) {
    //6/2/1932 -> duong lich-> no7
    const exactlyDate = new Date(
      dateTime?.getFullYear(),
      dateTime.getMonth(),
      dateTime.getDate()
    );
    let result = 7;
    let beginDate = new Date(1932, 1, 6);
    //ha chi mảng so 1, dong chi mảng so 2

    while (beginDate.getTime() < exactlyDate.getTime()) {
      let dongChiHaChi = this.getDongChiHaChi(beginDate?.getFullYear());
      let ngayHaChi = new Date(
        beginDate?.getFullYear(),
        dongChiHaChi[0][1] - 1,
        dongChiHaChi[0][0]
      );
      let ngayDongChi = new Date(
        beginDate?.getFullYear(),
        dongChiHaChi[1][1] - 1,
        dongChiHaChi[1][0]
      );

      // trước hạ chí thì ++

      if (beginDate.getTime() < ngayHaChi.getTime()) {
        result++;
      } else if (
        beginDate.getMonth() === ngayHaChi.getMonth() &&
        beginDate.getDate() === ngayHaChi.getDate() &&
        beginDate?.getFullYear() === ngayHaChi?.getFullYear()
      ) {
        if (
          beginDate?.getFullYear() === 1957 ||
          beginDate?.getFullYear() === 1980 ||
          beginDate?.getFullYear() === 2003 ||
          beginDate?.getFullYear() === 2026 ||
          beginDate?.getFullYear() === 2049
        ) {
          result = 6 - result;
        } else {
          result = 9 - result;
        }
      }
      //trước đông chí và sau hạ chí
      else if (
        beginDate.getTime() < ngayDongChi.getTime() &&
        beginDate.getTime() > ngayHaChi.getTime()
      ) {
        result--;
      } else if (
        beginDate.getMonth() === ngayDongChi.getMonth() &&
        beginDate.getDate() === ngayDongChi.getDate() &&
        beginDate?.getFullYear() === ngayDongChi?.getFullYear()
      ) {
        if (
          beginDate?.getFullYear() === 1934 ||
          beginDate?.getFullYear() === 1945 ||
          beginDate?.getFullYear() === 1968 ||
          beginDate?.getFullYear() === 1991 ||
          beginDate?.getFullYear() === 2014 ||
          beginDate?.getFullYear() === 2037
        ) {
          result = 14 - result;
        } else {
          result = 11 - result;
        }
      }

      //sau đông chí
      else if (beginDate.getTime() > ngayDongChi.getTime()) {
        result++;
      }

      // -----
      result = result % 9;
      if (result === 0) {
        result = 9;
      }

      beginDate.setDate(beginDate.getDate() + 1);
    }

    // result có 3 trường hợp

    //   1 là ngày hạ chí,
    // if (dateTime.getTime() === ngayHaChi.getTime()) {
    //   let tempNumber = 10 - result;
    //   return `${result}/${tempNumber}`;
    // } else {
    return result;
    // }
    //     2 là ngày đông chí
    // 3 là ngày bth,
  }

  getDongChiHaChi(fullYear) {
    const tempDateTime = new Date(fullYear, 1, 1);
    //theo lịch tiết khí bắt đầu từ năm 1932 để lấy khoảng cách
    const distance = tempDateTime?.getFullYear() - 1932;
    const rowNumber = distance * 42 + 39;
    const ngayDongChi = this.excelLichVanNien[rowNumber][21]
      .split("-")[0]
      .split(" ")[0]
      .split("/");
    const ngayHaChi = this.excelLichVanNien[rowNumber][9]
      .split("-")[0]
      .split(" ")[0]
      .split("/");

    return [ngayHaChi, ngayDongChi];
  }

  getIsSauDongChi(dateTime) {
    const tempDateTime = new Date(dateTime);
    //file excel bắt đầu từ năm 1932
    const distance = tempDateTime?.getFullYear() - 1932;

    //lấy tọa độ dòng trong excel
    const rowNumber = distance * 42 + 39;
    //chia nho chuoi lay ngay thang cua dong chi va ha chi
    const ngayDongChi = this.excelLichVanNien[rowNumber][21]
      .split("-")[0]
      .split(" ")[0]
      .split("/");
    const stempDongChi = new Date(
      tempDateTime?.getFullYear(),
      ngayDongChi[1] - 1,
      ngayDongChi[0]
    );

    const ngayHaChi = this.excelLichVanNien[rowNumber][9]
      .split("-")[0]
      .split(" ")[0]
      .split("/");
    const stempHaChi = new Date(
      tempDateTime?.getFullYear(),
      ngayHaChi[1] - 1,
      ngayHaChi[0]
    );

    // từ đầu năm đến hạ chí là sau đông chí ==> true
    // từ hạ chí đến ngày đông chí là trước đông chí ==> false
    // ngày được chọn lớn hơn ngày hạ chí là trước đông chí ==> false
    // ngày được chọn nhỏ hơn ngày hạ chí là sau đông chí ==> true

    //ngày được chọn > ngày đông chí ==>true
    //ngày được chọn < ngày hạ chí ==>true
    //ngày được chọn > ngày hạ chí ==>false
    if (
      tempDateTime.getTime() > stempDongChi.getTime() ||
      tempDateTime.getTime() < stempHaChi.getTime()
    ) {
      return true;
    } else {
      return false;
    }
    // if (tempDateTime.getTime() > stempHaChi.getTime()) {
    //   return false;
    // }
    // else {
    //   return true;
    // }
  }
  phiTinhNam(lunarCalendar) {
    const DateNew = new Date(lunarCalendar);

    const Year = DateNew?.getFullYear();
    const arrYear = String(Year).split("");
    let sumCharacter =
      Number(arrYear[0]) +
      Number(arrYear[1]) +
      Number(arrYear[2]) +
      Number(arrYear[3]);
    if (sumCharacter > 10) {
      let arrSumCharacter = String(sumCharacter).split("");
      sumCharacter = Number(arrSumCharacter[0]) + Number(arrSumCharacter[1]);
      if (sumCharacter >= 10) {
        let arrSumLast = String(sumCharacter).split("");
        sumCharacter = Number(arrSumLast[0]) + Number(arrSumLast[1]);
      }
    }
    const result = 11 - sumCharacter;
    return result;
  }
  convertSideToCompass(starNumber) {
    if (starNumber + 8 === 14 || starNumber + 8 === 5) {
      return { isActive: true, cordi: "135deg" };
    } else if (starNumber + 4 === 14 || starNumber + 4 === 5) {
      return { isActive: true, cordi: "180deg" };
    } else if (starNumber + 6 === 14 || starNumber + 6 === 5) {
      return { isActive: true, cordi: "225deg" };
    } else if (starNumber + 7 === 14 || starNumber + 7 === 5) {
      return { isActive: true, cordi: "90deg" };
    } else if (starNumber + 0 === 14 || starNumber + 0 === 5) {
      return { isActive: false, cordi: "-15deg" };
    } else if (starNumber + 2 === 14 || starNumber + 2 === 5) {
      return { isActive: true, cordi: "270deg" };
    } else if (starNumber + 3 === 14 || starNumber + 3 === 5) {
      return { isActive: true, cordi: "45deg" };
    } else if (starNumber + 5 === 14 || starNumber + 5 === 5) {
      return { isActive: true, cordi: "0deg" };
    } else if (starNumber + 1 === 14 || starNumber + 1 === 5) {
      return { isActive: true, cordi: "315deg" };
    }
  }
  convertTamSatSideOnCompass(chi) {
    switch (chi) {
      case Const.Chi.Dan.name:
      case Const.Chi.Ngo.name:
      case Const.Chi.Tuat.name:
        return "0deg";
      case Const.Chi.Hoi.name:
      case Const.Chi.Mao.name:
      case Const.Chi.Mui.name:
        return "270deg";
      case Const.Chi.Ti.name:
      case Const.Chi.Dau.name:
      case Const.Chi.Suu.name:
        return "90deg";
      case Const.Chi.Than.name:
      case Const.Chi.Ty.name:
      case Const.Chi.Thin.name:
        return "180deg";
      default:
        break;
    }
  }
  getTietKhiAllYear(dateTimeYear) {
    const distance = dateTimeYear?.getFullYear() - 1932;
    const thangLe = distance * 42 + 36;
    const thangChan = distance * 42 + 38;
    let ngayGioDauThang1 = this.excelLichVanNien[thangLe][1]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang3 = this.excelLichVanNien[thangLe][5]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang5 = this.excelLichVanNien[thangLe][9]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang7 = this.excelLichVanNien[thangLe][13]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang9 = this.excelLichVanNien[thangLe][17]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang11 = this.excelLichVanNien[thangLe][21]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang2 = this.excelLichVanNien[thangChan][2]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang4 = this.excelLichVanNien[thangChan][6]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang6 = this.excelLichVanNien[thangChan][10]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang8 = this.excelLichVanNien[thangChan][14]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang10 = this.excelLichVanNien[thangChan][18]
      .split(" - ")[0]
      .split(" ");
    let ngayGioDauThang12 = this.excelLichVanNien[thangChan][22]
      .split(" - ")[0]
      .split(" ");

    const ngayDauThangMot = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang1[0].split("/")[1]) - 1,
      Number(ngayGioDauThang1[0].split("/")[0]),
      Number(ngayGioDauThang1[1].split(":")[0]),
      Number(ngayGioDauThang1[1].split(":")[1])
    );
    const ngayDauThangHai = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang2[0].split("/")[1]) - 1,
      Number(ngayGioDauThang2[0].split("/")[0]),
      Number(ngayGioDauThang2[1].split(":")[0]),
      Number(ngayGioDauThang2[1].split(":")[1])
    );
    const ngayDauThangBa = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang3[0].split("/")[1]) - 1,
      Number(ngayGioDauThang3[0].split("/")[0]),
      Number(ngayGioDauThang3[1].split(":")[0]),
      Number(ngayGioDauThang3[1].split(":")[1])
    );
    const ngayDauThangTu = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang4[0].split("/")[1]) - 1,
      Number(ngayGioDauThang4[0].split("/")[0]),
      Number(ngayGioDauThang4[1].split(":")[0]),
      Number(ngayGioDauThang4[1].split(":")[1])
    );
    const ngayDauThangNam = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang5[0].split("/")[1]) - 1,
      Number(ngayGioDauThang5[0].split("/")[0]),
      Number(ngayGioDauThang5[1].split(":")[0]),
      Number(ngayGioDauThang5[1].split(":")[1])
    );
    const ngayDauThangSau = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang6[0].split("/")[1]) - 1,
      Number(ngayGioDauThang6[0].split("/")[0]),
      Number(ngayGioDauThang6[1].split(":")[0]),
      Number(ngayGioDauThang6[1].split(":")[1])
    );
    const ngayDauThangBay = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang7[0].split("/")[1]) - 1,
      Number(ngayGioDauThang7[0].split("/")[0]),
      Number(ngayGioDauThang7[1].split(":")[0]),
      Number(ngayGioDauThang7[1].split(":")[1])
    );
    const ngayDauThangTam = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang8[0].split("/")[1]) - 1,
      Number(ngayGioDauThang8[0].split("/")[0]),
      Number(ngayGioDauThang8[1].split(":")[0]),
      Number(ngayGioDauThang8[1].split(":")[1])
    );
    const ngayDauThangChin = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang9[0].split("/")[1]) - 1,
      Number(ngayGioDauThang9[0].split("/")[0]),
      Number(ngayGioDauThang9[1].split(":")[0]),
      Number(ngayGioDauThang9[1].split(":")[1])
    );
    const ngayDauThangMuoi = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang10[0].split("/")[1]) - 1,
      Number(ngayGioDauThang10[0].split("/")[0]),
      Number(ngayGioDauThang10[1].split(":")[0]),
      Number(ngayGioDauThang10[1].split(":")[1])
    );
    const ngayDauThangMuoiMot = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang11[0].split("/")[1]) - 1,
      Number(ngayGioDauThang11[0].split("/")[0]),
      Number(ngayGioDauThang11[1].split(":")[0]),
      Number(ngayGioDauThang11[1].split(":")[1])
    );
    const ngayDauThangMuoiHai = new Date(
      dateTimeYear.getFullYear(),
      Number(ngayGioDauThang12[0].split("/")[1]) - 1,
      Number(ngayGioDauThang12[0].split("/")[0]),
      Number(ngayGioDauThang12[1].split(":")[0]),
      Number(ngayGioDauThang12[1].split(":")[1])
    );
    let lichTietKhi = [];
    lichTietKhi.push(
      ngayDauThangMot,
      ngayDauThangHai,
      ngayDauThangBa,
      ngayDauThangTu,
      ngayDauThangNam,
      ngayDauThangSau,
      ngayDauThangBay,
      ngayDauThangTam,
      ngayDauThangChin,
      ngayDauThangMuoi,
      ngayDauThangMuoiMot,
      ngayDauThangMuoiHai
    );
    this.tietKhiDate = lichTietKhi;
  }
  getCorrectTietKhi(solarDate) {
    var day = solarDate.getDate();
    var month = solarDate.getMonth();
    var year =
      solarDate < this.tietKhiDate[1]
        ? solarDate.getFullYear() - 1
        : solarDate.getFullYear();
    // getTime()
    switch (solarDate.getMonth()) {
      // tháng 1 dương lịch nếu sau ngày đầu tháng 1 thì là tháng 12 năm ngoái
      //input ngày 1 tháng 1 2020
      // out put ngày 1 tháng 12 2019
      case 0:
        if (
          solarDate.getTime() === this.tietKhiDate[0].getTime() &&
          month === this.tietKhiDate[0].getMonth()
        ) {
          month = month;
        } else if (solarDate < this.tietKhiDate[0]) {
          month = 11;
        }
        break;
      case 1:
        // trước ngày lập xuân thì là tháng 12 của năm ngoái, ngược lại là tháng 1 năm sau
        if (
          solarDate.getTime() === this.tietKhiDate[1].getTime() &&
          month === this.tietKhiDate[1].getMonth()
        ) {
          month = month;
        } else if (solarDate < this.tietKhiDate[1]) {
          month = month - 1;
        }
        break;
      case 2:
        if (
          solarDate.getTime() === this.tietKhiDate[2].getTime() &&
          month === this.tietKhiDate[2].getMonth()
        ) {
          month = month;
        } else if (solarDate < this.tietKhiDate[2]) {
          month = month - 1;
        }
        break;
      case 3:
        if (
          solarDate.getTime() === this.tietKhiDate[3].getTime() &&
          month === this.tietKhiDate[3].getMonth()
        ) {
          month = month;
        } else if (solarDate < this.tietKhiDate[3]) {
          month = month - 1;
        }
        break;
      case 4:
        if (
          solarDate.getTime() === this.tietKhiDate[4].getTime() &&
          month === this.tietKhiDate[4].getMonth()
        ) {
          month = month;
        } else if (solarDate < this.tietKhiDate[4]) {
          month = month - 1;
        }
        break;
      case 5:
        if (
          solarDate.getTime() === this.tietKhiDate[5].getTime() &&
          month === this.tietKhiDate[5].getMonth()
        ) {
          month = month;
        } else if (solarDate < this.tietKhiDate[5]) {
          month = month - 1;
        }
        break;
      case 6:
        if (
          solarDate.getTime() === this.tietKhiDate[6].getTime() &&
          month === this.tietKhiDate[6].getMonth()
        ) {
          month = month;
        } else if (solarDate < this.tietKhiDate[6]) {
          month = month - 1;
        }
        break;
      case 7:
        if (
          solarDate.getTime() === this.tietKhiDate[7].getTime() &&
          month === this.tietKhiDate[7].getMonth()
        ) {
          month = month;
        } else if (solarDate < this.tietKhiDate[7]) {
          month = month - 1;
        }
        break;
      case 8:
        if (
          solarDate.getTime() === this.tietKhiDate[8].getTime() &&
          month === this.tietKhiDate[8].getMonth()
        ) {
          month = month;
        } else if (solarDate < this.tietKhiDate[8]) {
          month = month - 1;
        }
        break;
      case 9:
        if (
          solarDate.getTime() === this.tietKhiDate[9].getTime() &&
          month === this.tietKhiDate[9].getMonth()
        ) {
          month = month;
        } else if (solarDate < this.tietKhiDate[9]) {
          month = month - 1;
        }
        break;
      case 10:
        if (
          solarDate.getTime() === this.tietKhiDate[10].getTime() &&
          month === this.tietKhiDate[10].getMonth()
        ) {
          month = month;
        } else if (solarDate < this.tietKhiDate[10]) {
          month = month - 1;
        }
        break;
      case 11:
        if (
          solarDate.getTime() === this.tietKhiDate[11].getTime() &&
          month === this.tietKhiDate[11].getMonth()
        ) {
          month = month;
        } else if (solarDate < this.tietKhiDate[11]) {
          month = month - 1;
        }
        break;
      default:
        break;
    }
    return new Date(year, month, day);
  }
  getNgayKyAmLich(dateLunar) {
    const month = dateLunar[1];
    const day = dateLunar[0];
    // const year = dateLunar[2];
    switch (month) {
      case 1: {
        if (day === 6 || day === 9) {
          return Const.ngayKy.maVuongKyNhat.name;
        } else if (day === 13) {
          return Const.ngayKy.duongCongKyNhat.name;
        } else {
          return "";
        }
      }
      case 2: {
        if (day === 5 || day === 8) {
          return Const.ngayKy.maVuongKyNhat.name;
        } else if (day === 11) {
          return Const.ngayKy.duongCongKyNhat.name;
        } else {
          return "";
        }
      }
      case 3:
        if (day === 3 || day === 5) {
          return Const.ngayKy.maVuongKyNhat.name;
        } else if (day === 9) {
          return Const.ngayKy.duongCongKyNhat.name;
        } else {
          return "";
        }
      case 4:
        if (day === 5 || day === 18) {
          return Const.ngayKy.maVuongKyNhat.name;
        } else if (day === 7) {
          return Const.ngayKy.duongCongKyNhat.name;
        } else {
          return "";
        }
      case 5:
        if (day === 24 || day === 27) {
          return Const.ngayKy.maVuongKyNhat.name;
        } else if (day === 5) {
          return Const.ngayKy.duongCongKyNhat.name;
        } else {
          return "";
        }
      case 6:
        if (day === 23 || day === 26) {
          return Const.ngayKy.maVuongKyNhat.name;
        } else if (day === 3) {
          return Const.ngayKy.duongCongKyNhat.name;
        } else {
          return "";
        }
      case 7:
        if (day === 22 || day === 23) {
          return Const.ngayKy.maVuongKyNhat.name;
        } else if (day === 1) {
          return Const.ngayKy.duongCongKyNhat.name;
        } else {
          return "";
        }
      case 8:
        if (day === 27 || day === 30) {
          return Const.ngayKy.maVuongKyNhat.name;
        } else if (day === 27) {
          return Const.ngayKy.duongCongKyNhat.name;
        } else {
          return "";
        }
      case 9:
        if (day === 17 || day === 22) {
          return Const.ngayKy.maVuongKyNhat.name;
        } else if (day === 25) {
          return Const.ngayKy.duongCongKyNhat.name;
        } else {
          return "";
        }
      case 10:
        if (day === 2 || day === 16) {
          return Const.ngayKy.maVuongKyNhat.name;
        } else if (day === 23) {
          return Const.ngayKy.duongCongKyNhat.name;
        } else {
          return "";
        }
      case 11:
        if (day === 21) {
          return Const.ngayKy.duongCongKyNhat.name;
        } else {
          return "";
        }
      case 12:
        if (day === 19) {
          return Const.ngayKy.duongCongKyNhat.name;
        } else {
          return "";
        }
      default:
        break;
    }
  }
  getNgayKyTietKhi(solarCalendar) {
    // vì lịch tiết khí hiển thị ngày dương nên sử dụng lịch dương để lấy.
    //5 11 17 23 Tứ ly
    //lấy ngày xuân phân
    const ngayXuanPhanString = this.dataNhiTuTietKhi[5][0]
      .split(" ")[0]
      .split("/");
    const ngayXuanPhan = new Date(
      solarCalendar.getFullYear(),
      Number(ngayXuanPhanString[1]) - 1,
      Number(ngayXuanPhanString[0]),
      0,
      0,
      0,
      0
    );

    // lấy ngày hạ chí
    const ngayHaChiString = this.dataNhiTuTietKhi[11][0]
      .split(" ")[0]
      .split("/");
    const ngayHaChi = new Date(
      solarCalendar.getFullYear(),
      Number(ngayHaChiString[1]) - 1,
      Number(ngayHaChiString[0]),
      0,
      0,
      0,
      0
    );

    //lay ngay thu phan
    const ngayThuPhanString = this.dataNhiTuTietKhi[17][0]
      .split(" ")[0]
      .split("/");
    const ngayThuPhan = new Date(
      solarCalendar.getFullYear(),
      Number(ngayThuPhanString[1]) - 1,
      Number(ngayThuPhanString[0]),
      0,
      0,
      0,
      0
    );

    // lay ngay dong chi
    const ngayDongChiString = this.dataNhiTuTietKhi[23][0]
      .split(" ")[0]
      .split("/");
    const ngayDongChi = new Date(
      solarCalendar.getFullYear(),
      Number(ngayDongChiString[1]) - 1,
      Number(ngayDongChiString[0]),
      0,
      0,
      0,
      0
    );

    //2 8 14 20 tứ tuyệt

    //lay ngay Lap xuan ha thu dong
    const ngayLapXuanString = this.dataNhiTuTietKhi[2][0]
      .split(" ")[0]
      .split("/");
    const ngayLapXuan = new Date(
      solarCalendar.getFullYear(),
      Number(ngayLapXuanString[1]) - 1,
      Number(ngayLapXuanString[0]),
      0,
      0,
      0,
      0
    );
    const ngayLapHaString = this.dataNhiTuTietKhi[8][0]
      .split(" ")[0]
      .split("/");
    const ngayLapHa = new Date(
      solarCalendar.getFullYear(),
      Number(ngayLapHaString[1]) - 1,
      Number(ngayLapHaString[0]),
      0,
      0,
      0,
      0
    );

    const ngayLapThuString = this.dataNhiTuTietKhi[14][0]
      .split(" ")[0]
      .split("/");
    const ngayLapThu = new Date(
      solarCalendar.getFullYear(),
      Number(ngayLapThuString[1]) - 1,
      Number(ngayLapThuString[0]),
      0,
      0,
      0,
      0
    );

    const ngayLapDongString = this.dataNhiTuTietKhi[20][0]
      .split(" ")[0]
      .split("/");
    const ngayLapDong = new Date(
      solarCalendar.getFullYear(),
      Number(ngayLapDongString[1]) - 1,
      Number(ngayLapDongString[0]),
      0,
      0,
      0,
      0
    );

    if (
      solarCalendar.getTime() === ngayXuanPhan.getTime() - msADay ||
      solarCalendar.getTime() === ngayHaChi.getTime() - msADay ||
      solarCalendar.getTime() === ngayThuPhan.getTime() - msADay ||
      solarCalendar.getTime() === ngayDongChi.getTime() - msADay
    ) {
      return Const.ngayKy.tuLy.name;
    } else if (
      solarCalendar.getTime() === ngayLapXuan.getTime() - msADay ||
      solarCalendar.getTime() === ngayLapHa.getTime() - msADay ||
      solarCalendar.getTime() === ngayLapThu.getTime() - msADay ||
      solarCalendar.getTime() === ngayLapDong.getTime() - msADay
    ) {
      return Const.ngayKy.tuTuyet.name;
    } else {
      return "";
    }
  }
  getAmDonOrDuongDon(date) {
    //khoảng cách từ năm chọn đến năm 1932
    const distance = date?.getFullYear() - 1932;
    //lấy dòng(ngày của năm) =  ngày đã chọn (+1 bỏ qua dòng đầu tiên của file excel) *42 (42 là số dòng data của 1 năm)
    const getDateInYear = date?.getDate() + 1 + distance * 42;
    // lấy tháng = tháng đã chọn (+1 bỏ qua cột đầu tiên "ngày" vì mỗi tháng bị lặp 2 lần nên sẽ nhân cho 2 )
    const getMonthInyear = (date?.getMonth() + 1) * 2;

    //lấy giá trị của tọa độ của ngày đã chọn trong file excel
    return this.excelLichVanNien[getDateInYear][getMonthInyear];
  }
  getAmDonOrDuongDonTheoNgay(date) {
    //khoảng cách từ năm chọn đến năm 1932
    const distance = date?.getFullYear() - 1932;
    //lấy dòng(ngày của năm) =  ngày đã chọn (+1 bỏ qua dòng đầu tiên của file excel) *42 (42 là số dòng data của 1 năm)
    const getDateInYear = date?.getDate() + 1 + distance * 42;
    // lấy tháng = tháng đã chọn (+1 bỏ qua cột đầu tiên "ngày" vì mỗi tháng bị lặp 2 lần nên sẽ nhân cho 2 )
    const getMonthInyear = (date?.getMonth() + 1) * 2;
    //lấy giá trị của tọa độ của ngày đã chọn trong file excel
    return this.kyMonDonGiapNgay[getDateInYear][getMonthInyear];
  }
  get1080cuc(amDonDuongDon, canChiHour) {
    if (typeof amDonDuongDon === "number") {
      const dataCuc = this.dataDuongDon.filter(
        (v) => v.sheet === `Duong Don ${amDonDuongDon}`
      );
      return dataCuc.find(
        (v) => v.CanChi === `${canChiHour[0]} ${canChiHour[1]}`
      );
    } else {
      const dataCuc = this.dataAmDon.filter(
        (v) => v.sheet === `Am Don ${amDonDuongDon?.charAt(0)}`
      );
      return dataCuc.find(
        (v) => v.CanChi === `${canChiHour[0]} ${canChiHour[1]}`
      );
    }
  }
  get1080cucNew(amDonDuongDon, canChiHour) {
    if (typeof amDonDuongDon === "number") {
      const dataCuc = this.dataDuongDon_new.filter(
        (v) => v.sheet === `Duong Don ${amDonDuongDon}`
      );
      let item = dataCuc.find((v) =>
        v.CanChi.includes(canChiHour[0] + " " + canChiHour[1])
      );
      if (!item) {
        item = dataCuc.find((v) =>
          v.CanChi.includes(canChiHour[0] + " " + canChiHour[1])
        );
        if (!item) {
          item = dataCuc.find((v) =>
            v.CanChi.includes(canChiHour[0] + "  " + canChiHour[1])
          );
        }
        item.CanChi = canChiHour[0] + " " + canChiHour[1];
      }
      return item;

      // return dataCuc.find(
      //   (v) => v.CanChi === `${canChiHour[0]} ${canChiHour[1]}`
      // );
    } else {
      const dataCuc = this.dataAmDon_new.filter(
        (v) => v.sheet === `Am Don ${amDonDuongDon?.charAt(0)}`
      );
      let item = dataCuc.find((v) =>
        v.CanChi.includes(canChiHour[0] + " " + canChiHour[1])
      );
      if (!item) {
        item = dataCuc.find((v) =>
          v.CanChi.includes(canChiHour[0] + " " + canChiHour[1])
        );
        if (!item) {
          item = dataCuc.find((v) =>
            v.CanChi.includes(canChiHour[0] + "  " + canChiHour[1])
          );
        }
        item.CanChi = canChiHour[0] + " " + canChiHour[1];
      }
      return item;

      // return dataCuc.find(
      //   (v) => v.CanChi === `${canChiHour[0]} ${canChiHour[1]}`
      // );
    }
  }

  get1080cucMaoSon(amDonDuongDon, canChiHour, index) {
    index = index > 0 ? index : 9;

    if (typeof amDonDuongDon === "number") {
      const dataCuc = this.dataDuongDon_new.filter(
        (v) => v.sheet === `Duong Don ${index}`
      );

      let item = dataCuc.find((v) =>
        v.CanChi.includes(canChiHour[0] + " " + canChiHour[1])
      );
      if (!item) {
        item = dataCuc.find((v) =>
          v.CanChi.includes(canChiHour[0] + " " + canChiHour[1])
        );
        if (!item) {
          item = dataCuc.find((v) =>
            v.CanChi.includes(canChiHour[0] + "  " + canChiHour[1])
          );
        }
        item.CanChi = canChiHour[0] + " " + canChiHour[1];
      }

      return item;

      // return dataCuc.find(
      //   (v) => v.CanChi.includes(canChiHour?.name)
      // );
    } else {
      const dataCuc = this.dataAmDon_new.filter(
        (v) => v.sheet === `Am Don ${index}`
      );

      let item = dataCuc.find((v) =>
        v.CanChi.includes(canChiHour[0] + " " + canChiHour[1])
      );
      if (!item) {
        item = dataCuc.find((v) =>
          v.CanChi.includes(canChiHour[0] + " " + canChiHour[1])
        );
        if (!item) {
          item = dataCuc.find((v) =>
            v.CanChi.includes(canChiHour[0] + "  " + canChiHour[1])
          );
        }
        item.CanChi = canChiHour[0] + " " + canChiHour[1];
      }
      return item;

      // return dataCuc.find(
      //   (v) => v.CanChi === `${canChiHour?.name}`
      // );
    }
  }

  clean(arr) {
    const arr_new = [];
    for (let i = 0; i < arr.length; i++) {
      arr_new.push(arr[i].trim());
    }

    for (let i = 0; i < arr_new.length; i++) {
      if (
        arr_new[i] === null ||
        arr_new[i] === undefined ||
        arr_new[i] === ""
      ) {
        delete arr_new[i];
      }
    }

    const filteredArr = arr_new.filter(
      (element) => element !== undefined && element !== null
    );

    return filteredArr;
  }

  async loadExcel() {
    try {
      await fetch("1080-Cuc.xlsx")
        .then((res) => res.arrayBuffer())
        .then((ab) => {
          const wb = XLSX.read(ab, { type: "array" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets["Duong Don 1"];
          //lấy sheet Duong Don
          let dataDuongDon = [];
          let dataAmDon = [];

          for (let index = 1; index <= 9; index++) {
            // const element = array[index];

            let dataBasic = wb.Sheets[`Duong Don ${index}`];
            let data = XLSX.utils.sheet_to_json(dataBasic, { header: 1 });
            for (let row = 0; row < data.length; row++) {
              let dataEachRow = data[row];
              let no = dataEachRow[1] && dataEachRow[1].toString().trim();
              if (no && no.includes(".")) {
                let temp = {};
                temp.CanChi = no.split(".")[1].trim();
                temp.sheet = `Duong Don ${index}`;
                // let tuanThuTruc = dataEachRow[3]
                //   .toString()
                //   .trim()
                //   .split("Trực Tinh");
                // temp.tuanThu = tuanThuTruc[0].trim();
                // temp.trucTinh = "Trực Tinh" + tuanThuTruc[1].trim();
                // temp.trucXu = data[row + 1][3].toString().trim();

                let tuanThuTruc = dataEachRow[3]
                  .toString()
                  .trim()
                  .split("Trực Tinh");
                temp.tuanThu = tuanThuTruc[0].trim();
                temp.trucTinh = "Trực Tinh" + tuanThuTruc[1].trim();
                temp.trucXu = data[row + 1][3].toString().trim();
                //box 1 2 3
                let row11a = data[row + 2][1].toString().trim().split(" ");
                let row11b = data[row + 2][2].toString().trim().split(" ");
                let row11c = data[row + 2][3].toString().trim().split(" ");
                let row12a = data[row + 3][1].toString().trim().split(" ");
                let row12b = data[row + 3][2].toString().trim().split(" ");
                let row12c = data[row + 3][3].toString().trim().split(" ");

                //box 4 5 6
                let row21a = data[row + 4][1].toString().trim().split(" ");
                let row21b = data[row + 4][2].toString().trim().split(" ");
                let row21c = data[row + 4][3].toString().trim().split(" ");
                let row22a = data[row + 5][1].toString().trim().split(" ");
                let row22b = data[row + 5][2].toString().trim().split(" ");
                let row22c = data[row + 5][3].toString().trim().split(" ");

                //box 7 8 9
                let row31a = data[row + 6][1].toString().trim().split(" ");
                let row31b = data[row + 6][2].toString().trim().split(" ");
                let row31c = data[row + 6][3].toString().trim().split(" ");
                let row32a = data[row + 7][1].toString().trim().split(" ");
                let row32b = data[row + 7][2].toString().trim().split(" ");
                let row32c = data[row + 7][3].toString().trim().split(" ");

                let box1 = {
                  thienBan: row11a[0].toString().trim(),
                  cuuTinh: row11a[row11a.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row12a[0].toString().trim(),
                  batMon: row12a[1].toString().trim(),
                  batThan: row12a[2].toString().trim(),
                  huong: Const.huong.DONG_NAM,
                  number: row11a[2] ? row11a[2] : 0,
                  batQuai: "Tốn",
                };
                let box2 = {
                  thienBan: row11b[0].toString().trim(),
                  cuuTinh: row11b[row11b.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row12b[0].toString().trim(),
                  batMon: row12b[1].toString().trim(),
                  batThan: row12b[2]
                    ? row12b[2].toString().trim()
                    : row12b[1].toString().trim(),
                  huong: Const.huong.NAM,
                  number: row11b[2] ? row11b[2] : 0,
                  batQuai: "Ly",
                };
                let box3 = {
                  thienBan: row11c[0].toString().trim(),
                  cuuTinh: row11c[row11c.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row12c[0].toString().trim(),
                  batMon: row12c[1].toString().trim(),
                  batThan: row12c[2].toString().trim(),
                  huong: Const.huong.TAY_NAM,
                  number: row11c[2] ? row11c[2] : 0,

                  batQuai: "Khôn",
                };
                let box4 = {
                  thienBan: row21a[0].toString().trim(),
                  cuuTinh: row21a[row21a.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row22a[0].toString().trim(),
                  batMon: row22a[1].toString().trim(),
                  batThan: row22a[2].toString().trim(),
                  huong: Const.huong.DONG,
                  number: row21a[2] ? row21a[2] : 0,
                  batQuai: "Chấn",
                };
                let box5 = {
                  thienBan: row21b[0].toString().trim(),
                  cuuTinh: row21b[row21b.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row21b[0].toString().trim(),
                  batMon: null,
                  batThan: null,
                  batQuai: null,
                };
                let box6 = {
                  thienBan: row21c[0].toString().trim(),
                  cuuTinh: row21c[row21c.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row22c[0].toString().trim(),
                  batMon: row22c[1].toString().trim(),
                  batThan: row22c[2].toString().trim(),
                  huong: Const.huong.TAY,
                  number: row21c[2] ? row21c[2] : 0,

                  batQuai: "Đoài",
                };
                let box7 = {
                  thienBan: row31a[0].toString().trim(),
                  cuuTinh: row31a[row31a.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row32a[0].toString().trim(),
                  batMon: row32a[1].toString().trim(),
                  batThan: row32a[2].toString().trim(),
                  huong: Const.huong.DONG_BAC,
                  number: row31a[2] ? row31a[2] : 0,

                  batQuai: "Cấn",
                };
                let box8 = {
                  thienBan: row31b[0].toString().trim(),
                  cuuTinh: row31b[row31b.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row32b[0].toString().trim(),
                  batMon: row32b[1].toString().trim(),
                  batThan: row32b[2].toString().trim(),
                  huong: Const.huong.BAC,
                  number: row31b[2] ? row31b[2] : 0,

                  batQuai: "Khảm",
                };
                let box9 = {
                  thienBan: row31c[0].toString().trim(),
                  cuuTinh: row31c[row31c.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row32c[0].toString().trim(),
                  batMon: row32c[1].toString().trim(),
                  batThan: row32c[2].toString().trim(),
                  huong: Const.huong.TAY_BAC,
                  number: row31c[2] ? row31c[2] : 0,

                  batQuai: "Càn",
                };
                temp.box1 = box1;
                temp.box2 = box2;
                temp.box3 = box3;
                temp.box4 = box4;
                temp.box5 = box5;
                temp.box6 = box6;
                temp.box7 = box7;
                temp.box8 = box8;
                temp.box9 = box9;
                dataDuongDon.push(temp);
              }
            }
            this.dataDuongDon = dataDuongDon;
          }
          for (let index = 1; index <= 9; index++) {
            // const element = array[index];

            let dataBasic = wb.Sheets[`Am Don ${index}`];
            let data = XLSX.utils.sheet_to_json(dataBasic, { header: 1 });
            for (let row = 0; row < data.length; row++) {
              let dataEachRow = data[row];
              let no = dataEachRow[1] && dataEachRow[1].toString().trim();
              if (no && no.includes(".")) {
                let temp = {};
                temp.CanChi = no.split(".")[1].trim();
                temp.sheet = `Am Don ${index}`;

                let tuanThuTruc = dataEachRow[3]
                  .toString()
                  .trim()
                  .split("Trực Tinh");
                temp.tuanThu = tuanThuTruc[0].trim();
                temp.trucTinh = "Trực Tinh" + tuanThuTruc[1].trim();
                temp.trucXu = data[row + 1][3].toString().trim();

                //box 1 2 3
                let row11a = data[row + 2][1].toString().trim().split(" ");
                let row11b = data[row + 2][2].toString().trim().split(" ");
                let row11c = data[row + 2][3].toString().trim().split(" ");
                let row12a = data[row + 3][1].toString().trim().split(" ");
                let row12b = data[row + 3][2].toString().trim().split(" ");
                let row12c = data[row + 3][3].toString().trim().split(" ");

                //box 4 5 6
                let row21a = data[row + 4][1].toString().trim().split(" ");
                let row21b = data[row + 4][2].toString().trim().split(" ");
                let row21c = data[row + 4][3].toString().trim().split(" ");
                let row22a = data[row + 5][1].toString().trim().split(" ");
                let row22b = data[row + 5][2].toString().trim().split(" ");
                let row22c = data[row + 5][3].toString().trim().split(" ");

                //box 7 8 9
                let row31a = data[row + 6][1].toString().trim().split(" ");
                let row31b = data[row + 6][2].toString().trim().split(" ");
                let row31c = data[row + 6][3].toString().trim().split(" ");
                let row32a = data[row + 7][1].toString().trim().split(" ");
                let row32b = data[row + 7][2].toString().trim().split(" ");
                let row32c = data[row + 7][3].toString().trim().split(" ");

                let box1 = {
                  thienBan: row11a[0].toString().trim(),
                  cuuTinh: row11a[row11a.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row12a[0].toString().trim(),
                  batMon: row12a[1].toString().trim(),
                  batThan: row12a[2].toString().trim(),
                  number: row11a[2] ? row11a[2] : 0,
                  huong: Const.huong.DONG_NAM,
                  batQuai: "Tốn",
                };
                let box2 = {
                  thienBan: row11b[0].toString().trim(),
                  cuuTinh: row11b[row11b.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row12b[0].toString().trim(),
                  batMon: row12b[1].toString().trim(),
                  batThan: row12b[2].toString().trim(),
                  number: row11b[2] ? row11b[2] : 0,
                  huong: Const.huong.NAM,
                  batQuai: "Ly",
                };
                let box3 = {
                  thienBan: row11c[0].toString().trim(),
                  cuuTinh: row11c[row11c.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row12c[0].toString().trim(),
                  batMon: row12c[1].toString().trim(),
                  batThan: row12c[2].toString().trim(),
                  number: row11c[2] ? row11c[2] : 0,
                  huong: Const.huong.TAY_NAM,
                  batQuai: "Khôn",
                };
                let box4 = {
                  thienBan: row21a[0].toString().trim(),
                  cuuTinh: row21a[row21a.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row22a[0].toString().trim(),
                  batMon: row22a[1].toString().trim(),
                  batThan: row22a[2]
                    ? row22a[2].toString().trim()
                    : row22a[1].toString().trim(),
                  number: row21a[2] ? row21a[2] : 0,
                  huong: Const.huong.DONG,
                  batQuai: "Chấn",
                };
                let box5 = {
                  thienBan: row21b[0].toString().trim(),
                  cuuTinh: row21b[row21b.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row21b[0].toString().trim(),
                  batMon: null,
                  batThan: null,
                  batQuai: null,
                };
                let box6 = {
                  thienBan: row21c[0].toString().trim(),
                  cuuTinh: row21c[row21c.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row22c[0].toString().trim(),
                  batMon: row22c[1].toString().trim(),
                  batThan: row22c[2]
                    ? row22c[2].toString().trim()
                    : row22c[1].toString().trim(),
                  number: row21c[2] ? row21c[2] : 0,
                  huong: Const.huong.TAY,
                  batQuai: "Đoài",
                };
                let box7 = {
                  thienBan: row31a[0].toString().trim(),
                  cuuTinh: row31a[row31a.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row32a[0].toString().trim(),
                  batMon: row32a[1].toString().trim(),
                  batThan: row32a[2]
                    ? row32a[2].toString().trim()
                    : row32a[1].toString().trim(),
                  number: row31a[2] ? row31a[2] : 0,

                  huong: Const.huong.DONG_BAC,
                  batQuai: "Cấn",
                };
                let box8 = {
                  thienBan: row31b[0].toString().trim(),
                  cuuTinh: row31b[row31b.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row32b[0].toString().trim(),
                  batMon: row32b[1].toString().trim(),
                  batThan: row32b[2].toString().trim(),
                  number: row31b[2] ? row31b[2] : 0,

                  huong: Const.huong.BAC,
                  batQuai: "Khảm",
                };
                let box9 = {
                  thienBan: row31c[0].toString().trim(),
                  cuuTinh: row31c[row31c.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row32c[0].toString().trim(),
                  batMon: row32c[1].toString().trim(),
                  batThan: row32c[2].toString().trim(),
                  number: row31c[2] ? row31c[2] : 0,

                  huong: Const.huong.TAY_BAC,
                  batQuai: "Càn",
                };

                temp.box1 = box1;
                temp.box2 = box2;
                temp.box3 = box3;
                temp.box4 = box4;
                temp.box5 = box5;
                temp.box6 = box6;
                temp.box7 = box7;
                temp.box8 = box8;
                temp.box9 = box9;
                dataAmDon.push(temp);
              }
            }
            this.dataAmDon = dataAmDon;
          }
        });
      await fetch("ABMS-1080.xlsx")
        .then((res) => res.arrayBuffer())
        .then((ab) => {
          const wb = XLSX.read(ab, { type: "array" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets["Duong Don 1"];
          //lấy sheet Duong Don
          let dataDuongDon = [];
          let dataAmDon = [];

          for (let index = 1; index <= 9; index++) {
            // const element = array[index];

            let dataBasic = wb.Sheets[`Duong Don ${index}`];
            if (index === 9) {
            }
            let data = XLSX.utils.sheet_to_json(dataBasic, { header: 1 });
            for (let row = 0; row < data.length; row++) {
              let dataEachRow = data[row];

              let no = dataEachRow[1] && dataEachRow[1].toString().trim();

              if (no && no.includes(".")) {
                let temp = {};
                temp.CanChi = no.split(".")[1].trim();
                temp.sheet = `Duong Don ${index}`;
                let tuanThuTruc = dataEachRow[3]
                  .toString()
                  .trim()
                  .split("Phù Đầu:");

                const a = tuanThuTruc[1]?.split("Trực Tinh：")
                  ? tuanThuTruc[1]?.split("Trực Tinh：")
                  : tuanThuTruc[0]?.split("Trực Tinh：");
                temp.phuDau = a[0].trim();
                temp.trucPhu = a[1].trim();
                temp.trucXu = data[row + 1][3].split("：")[1]?.trim();
                // temp.trucTinh = data[row + 2][3].includes("Trực Tinh") ? data[row + 2][3].split(':')[1] : "";
                temp.trucTinh = a[1].trim();

                let value_row_1 = row + 2;
                let value_row_2 = row + 3;
                let value_row_3 = row + 4;
                let value_row_4 = row + 5;
                let value_row_5 = row + 6;
                let value_row_6 = row + 7;

                // if (temp.trucTinh) {
                //   value_row_1 = row + 3
                //   value_row_2 = row + 4
                //   value_row_3 = row + 5
                //   value_row_4 = row + 6
                //   value_row_5 = row + 7
                //   value_row_6 = row + 8
                // } else {
                //   value_row_1 = row + 2;
                //   value_row_2 = row + 3;
                //   value_row_3 = row + 4;
                //   value_row_4 = row + 5;
                //   value_row_5 = row + 6;
                //   value_row_6 = row + 7;
                // }

                //box 1 2 3
                let row11a = data[value_row_1][1].split(" ");
                row11a = this.clean(row11a);
                let row11b = data[value_row_1][2].toString().trim().split(" ");
                row11b = this.clean(row11b);

                let row11c = data[value_row_1][3].toString().trim().split(" ");
                row11c = this.clean(row11c);

                let row12a = data[value_row_2][1].toString().trim().split(" ");
                row12a = this.clean(row12a);

                let row12b = data[value_row_2][2].toString().trim().split(" ");
                row12b = this.clean(row12b);

                let row12c = data[value_row_2][3].toString().trim().split(" ");
                row12c = this.clean(row12c);

                //box 4 5 6
                let row21a = data[value_row_3][1].toString().trim().split(" ");
                row21a = this.clean(row21a);

                let row21b = data[value_row_3][2].toString().trim().split(" ");
                row21b = this.clean(row21b);

                let row21c = data[value_row_3][3].toString().trim().split(" ");
                row21c = this.clean(row21c);

                let row22a = data[value_row_4][1].toString().trim().split(" ");
                row22a = this.clean(row22a);

                // let row22b = data[value_row_4][2].toString().trim().split(" ");
                // // row22b = this.clean(row22b)

                let row22c = data[value_row_4][3].toString().trim().split(" ");
                row22c = this.clean(row22c);

                //box 7 8 9
                let row31a = data[value_row_5][1].toString().trim().split(" ");
                row31a = this.clean(row31a);

                let row31b = data[value_row_5][2].toString().trim().split(" ");
                row31b = this.clean(row31b);

                let row31c = data[value_row_5][3].toString().trim().split(" ");
                row31c = this.clean(row31c);

                let row32a = data[value_row_6][1].toString().trim().split(" ");
                row32a = this.clean(row32a);

                let row32b = data[value_row_6][2].toString().trim().split(" ");
                row32b = this.clean(row32b);

                let row32c = data[value_row_6][3].toString().trim().split(" ");
                row32c = this.clean(row32c);

                let box1 = {
                  thienBan: row11a[0].toString().trim(),
                  cuuTinh: row11a[row11a.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row12a[0].toString().trim(),
                  batMon: row12a[1].toString().trim(),
                  batThan: row12a[2].toString().trim(),
                  huong: Const.huong.DONG_NAM,
                  number: row11a[2] ? row11a[2] : 0,
                  batQuai: "Tốn",
                };
                let box2 = {
                  thienBan: row11b[0].toString().trim(),
                  cuuTinh: row11b[row11b.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row12b[0].toString().trim(),
                  batMon: row12b[1].toString().trim(),
                  batThan: row12b[2]
                    ? row12b[2].toString().trim()
                    : row12b[1].toString().trim(),
                  huong: Const.huong.NAM,
                  number: row11b[2] ? row11b[2] : 0,
                  batQuai: "Ly",
                };
                let box3 = {
                  thienBan: row11c[0].toString().trim(),
                  cuuTinh: row11c[row11c.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row12c[0].toString().trim(),
                  batMon: row12c[1].toString().trim(),
                  batThan: row12c[2].toString().trim(),
                  huong: Const.huong.TAY_NAM,
                  number: row11c[2] ? row11c[2] : 0,

                  batQuai: "Khôn",
                };
                let box4 = {
                  thienBan: row21a[0].toString().trim(),
                  cuuTinh: row21a[row21a.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row22a[0].toString().trim(),
                  batMon: row22a[1].toString().trim(),
                  batThan: row22a[2].toString().trim(),
                  huong: Const.huong.DONG,
                  number: row21a[2] ? row21a[2] : 0,
                  batQuai: "Chấn",
                };
                let box5 = {
                  thienBan: row21b[0].toString().trim(),
                  cuuTinh: null,
                  diaBan: row21b[0].toString().trim(),
                  batMon: null,
                  batThan: null,
                  batQuai: null,
                };
                let box6 = {
                  thienBan: row21c[0].toString().trim(),
                  cuuTinh: row21c[row21c.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row22c[0].toString().trim(),
                  batMon: row22c[1].toString().trim(),
                  batThan: row22c[2].toString().trim(),
                  huong: Const.huong.TAY,
                  number: row21c[2] ? row21c[2] : 0,

                  batQuai: "Đoài",
                };
                let box7 = {
                  thienBan: row31a[0].toString().trim(),
                  cuuTinh: row31a[row31a.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row32a[0].toString().trim(),
                  batMon: row32a[1].toString().trim(),
                  batThan: row32a[2].toString().trim(),
                  huong: Const.huong.DONG_BAC,
                  number: row31a[2] ? row31a[2] : 0,

                  batQuai: "Cấn",
                };
                let box8 = {
                  thienBan: row31b[0].toString().trim(),
                  cuuTinh: row31b[row31b.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row32b[0].toString().trim(),
                  batMon: row32b[1].toString().trim(),
                  batThan: row32b[2].toString().trim(),
                  huong: Const.huong.BAC,
                  number: row31b[2] ? row31b[2] : 0,

                  batQuai: "Khảm",
                };
                let box9 = {
                  thienBan: row31c[0].toString().trim(),
                  cuuTinh: row31c[row31c.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row32c[0].toString().trim(),
                  batMon: row32c[1].toString().trim(),
                  batThan: row32c[2].toString().trim(),
                  huong: Const.huong.TAY_BAC,
                  number: row31c[2] ? row31c[2] : 0,

                  batQuai: "Càn",
                };
                temp.box1 = box1;
                temp.box2 = box2;
                temp.box3 = box3;
                temp.box4 = box4;
                temp.box5 = box5;
                temp.box6 = box6;
                temp.box7 = box7;
                temp.box8 = box8;
                temp.box9 = box9;
                dataDuongDon.push(temp);
              }
            }
            this.dataDuongDon_new = dataDuongDon;
          }
          for (let index = 1; index <= 9; index++) {
            // const element = array[index];

            let dataBasic = wb.Sheets[`Am Don ${index}`];
            let data = XLSX.utils.sheet_to_json(dataBasic, { header: 1 });
            for (let row = 0; row < data.length; row++) {
              let dataEachRow = data[row];
              let no = dataEachRow[1] && dataEachRow[1].toString().trim();
              if (no && no.includes(".")) {
                let temp = {};
                temp.CanChi = no.split(".")[1].trim();
                temp.sheet = `Am Don ${index}`;

                let tuanThuTruc = dataEachRow[3]
                  .toString()
                  .trim()
                  .split("Phù Đầu:");

                const a = tuanThuTruc[1]?.split("Trực Tinh：")
                  ? tuanThuTruc[1]?.split("Trực Tinh：")
                  : tuanThuTruc[0]?.split("Trực Tinh：");
                temp.phuDau = a[0].trim();
                temp.trucPhu = a[1].trim();
                temp.trucXu = data[row + 1][3].split("：")[1]?.trim();
                // temp.trucTinh = data[row + 2][3].includes("Trực Tinh") ? data[row + 2][3].split(':')[1] : "";
                temp.trucTinh = a[1].trim();

                let value_row_1 = row + 2;
                let value_row_2 = row + 3;
                let value_row_3 = row + 4;
                let value_row_4 = row + 5;
                let value_row_5 = row + 6;
                let value_row_6 = row + 7;

                // if (temp.trucTinh) {
                //   value_row_1 = row + 3
                //   value_row_2 = row + 4
                //   value_row_3 = row + 5
                //   value_row_4 = row + 6
                //   value_row_5 = row + 7
                //   value_row_6 = row + 8
                // } else {
                //   value_row_1 = row + 2;
                //   value_row_2 = row + 3;
                //   value_row_3 = row + 4;
                //   value_row_4 = row + 5;
                //   value_row_5 = row + 6;
                //   value_row_6 = row + 7;
                // }

                //box 1 2 3
                let row11a = data[value_row_1][1].toString().trim().split(" ");
                row11a = this.clean(row11a);

                let row11b = data[value_row_1][2].toString().trim().split(" ");
                row11b = this.clean(row11b);

                let row11c = data[value_row_1][3].toString().trim().split(" ");
                row11c = this.clean(row11c);

                let row12a = data[value_row_2][1].toString().trim().split(" ");
                row12a = this.clean(row12a);

                let row12b = data[value_row_2][2].toString().trim().split(" ");
                row12b = this.clean(row12b);

                let row12c = data[value_row_2][3].toString().trim().split(" ");
                row12c = this.clean(row12c);

                //box 4 5 6
                let row21a = data[value_row_3][1].toString().trim().split(" ");
                row21a = this.clean(row21a);

                let row21b = data[value_row_3][2].toString().trim().split(" ");
                row21b = this.clean(row21b);

                let row21c = data[value_row_3][3].toString().trim().split(" ");
                row21c = this.clean(row21c);

                let row22a = data[value_row_4][1].toString().trim().split(" ");
                row22a = this.clean(row22a);

                // let row22b = data[value_row_4][2].toString().trim().split(" ");
                // row22b = this.clean(row22b)

                let row22c = data[value_row_4][3].toString().trim().split(" ");
                row22c = this.clean(row22c);

                //box 7 8 9
                let row31a = data[value_row_5][1].toString().trim().split(" ");
                row31a = this.clean(row31a);

                let row31b = data[value_row_5][2].toString().trim().split(" ");
                row31b = this.clean(row31b);

                let row31c = data[value_row_5][3].toString().trim().split(" ");
                row31c = this.clean(row31c);

                let row32a = data[value_row_6][1].toString().trim().split(" ");
                row32a = this.clean(row32a);

                let row32b = data[value_row_6][2].toString().trim().split(" ");
                row32b = this.clean(row32b);

                let row32c = data[value_row_6][3].toString().trim().split(" ");
                row32c = this.clean(row32c);

                let box1 = {
                  thienBan: row11a[0].toString().trim(),
                  cuuTinh: row11a[row11a.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row12a[0].toString().trim(),
                  batMon: row12a[1].toString().trim(),
                  batThan: row12a[2].toString().trim(),
                  number: row11a[2] ? row11a[2] : 0,
                  huong: Const.huong.DONG_NAM,
                };
                let box2 = {
                  thienBan: row11b[0].toString().trim(),
                  cuuTinh: row11b[row11b.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row12b[0].toString().trim(),
                  batMon: row12b[1].toString().trim(),
                  batThan: row12b[2].toString().trim(),
                  number: row11b[2] ? row11b[2] : 0,
                  huong: Const.huong.NAM,
                };
                let box3 = {
                  thienBan: row11c[0].toString().trim(),
                  cuuTinh: row11c[row11c.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row12c[0].toString().trim(),
                  batMon: row12c[1].toString().trim(),
                  batThan: row12c[2] ? row12c[2].toString().trim() : "",
                  number: row11c[2] ? row11c[2] : 0,
                  huong: Const.huong.TAY_NAM,
                };
                let box4 = {
                  thienBan: row21a[0].toString().trim(),
                  cuuTinh: row21a[row21a.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row22a[0].toString().trim(),
                  batMon: row22a[1].toString().trim(),
                  batThan: row22a[2]
                    ? row22a[2].toString().trim()
                    : row22a[1].toString().trim(),
                  number: row21a[2] ? row21a[2] : 0,
                  huong: Const.huong.DONG,
                };
                let box5 = {
                  thienBan: row21b[0].toString().trim(),
                  cuuTinh: null,
                  diaBan: row21b[0].toString().trim(),
                  batMon: null,
                  batThan: null,
                  batQuai: null,
                };
                let box6 = {
                  thienBan: row21c[0].toString().trim(),
                  cuuTinh: row21c[row21c.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row22c[0].toString().trim(),
                  batMon: row22c[1].toString().trim(),
                  batThan: row22c[2]
                    ? row22c[2].toString().trim()
                    : row22c[1].toString().trim(),
                  number: row21c[2] ? row21c[2] : 0,
                  huong: Const.huong.TAY,
                };
                let box7 = {
                  thienBan: row31a[0].toString().trim(),
                  cuuTinh: row31a[row31a.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row32a[0].toString().trim(),
                  batMon: row32a[1].toString().trim(),
                  batThan: row32a[2]
                    ? row32a[2].toString().trim()
                    : row32a[1].toString().trim(),
                  number: row31a[2] ? row31a[2] : 0,

                  huong: Const.huong.DONG_BAC,
                };
                let box8 = {
                  thienBan: row31b[0].toString().trim(),
                  cuuTinh: row31b[row31b.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row32b[0].toString().trim(),
                  batMon: row32b[1].toString().trim(),
                  batThan: row32b[2].toString().trim(),
                  number: row31b[2] ? row31b[2] : 0,

                  huong: Const.huong.BAC,
                };
                let box9 = {
                  thienBan: row31c[0].toString().trim(),
                  cuuTinh: row31c[row31c.length > 1 ? 1 : 0].toString().trim(),
                  diaBan: row32c[0].toString().trim(),
                  batMon: row32c[1].toString().trim(),
                  batThan: row32c[2].toString().trim(),
                  number: row31c[2] ? row31c[2] : 0,

                  huong: Const.huong.TAY_BAC,
                };
                temp.box1 = box1;
                temp.box2 = box2;
                temp.box3 = box3;
                temp.box4 = box4;
                temp.box5 = box5;
                temp.box6 = box6;
                temp.box7 = box7;
                temp.box8 = box8;
                temp.box9 = box9;
                dataAmDon.push(temp);
              }
            }
            this.dataAmDon_new = dataAmDon;
          }
        });
    } catch (error) {
      console.log(error);
    }

    try {
      await fetch("lich_van_nien.xlsx")
        .then((res) => res.arrayBuffer())
        .then((ab) => {
          const wb = XLSX.read(ab, { type: "array" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
          this.excelLichVanNien = data;
        });
    } catch (error) { }
    try {
      await fetch("ky-mon-don-giap-thang-nam.xlsx")
        .then((res) => res.arrayBuffer())
        .then((ab) => {
          const wb = XLSX.read(ab, { type: "array" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
          const tempData = [];
          for (let index = 2; index < data.length; index++) {
            let nam = data[index][0];
            let canChiNam = data[index][1] + " " + data[index][2];
            let canChiThang1 = data[index][3] + " " + data[index][4];
            let canChiThang2 = data[index][5] + " " + data[index][6];
            let canChiThang3 = data[index][7] + " " + data[index][8];
            let canChiThang4 = data[index][9] + " " + data[index][10];
            let canChiThang5 = data[index][11] + " " + data[index][12];
            let canChiThang6 = data[index][13] + " " + data[index][14];
            let canChiThang7 = data[index][15] + " " + data[index][16];
            let canChiThang8 = data[index][17] + " " + data[index][18];
            let canChiThang9 = data[index][19] + " " + data[index][20];
            let canChiThang10 = data[index][21] + " " + data[index][22];
            let canChiThang11 = data[index][23] + " " + data[index][24];
            let canChiThang12 = data[index][25] + " " + data[index][26];
            let amDonNam = data[index][27];
            let amDonThang = data[index][28];

            tempData.push({
              nam: nam,
              canChiNam: canChiNam,

              amDonNam: `${amDonNam}A`,
              amDonThang: `${amDonThang}A`,
            });
          }
          this.kyMonDonGiapThangNam = tempData;
        });
    } catch (error) { }
    try {
      await fetch("ky-mon-don-giap-theo-ngay.xlsx")
        .then((res) => res.arrayBuffer())
        .then((ab) => {
          const wb = XLSX.read(ab, { type: "array" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

          this.kyMonDonGiapNgay = data;
        });
    } catch (error) { }
  }
  async refreshPage() {
    const { date, dateData, toaNumber, namSinhNumber } = this.state;
    this.setState({ isLoading: true });

    await this.loadExcel();
    const tempTime = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes()
    );
    this.getDongChiHaChi(tempTime.getFullYear());
    let dateShowBellowSolarDate = lunar.solar2Lunar(
      date.getDate(),
      date.getMonth() + 1,
      date.getFullYear()
    );
    const amDonOrDuongDonGio = this.getAmDonOrDuongDon(tempTime);
    const amDonOrDuongDonNgay = this.getAmDonOrDuongDonTheoNgay(tempTime);
    const starDay = this.getDayStarOfADate(tempTime);

    // lấy lịch tiết khí của cả 1 năm
    this.getTietKhiAllYear(tempTime);
    // lấy đầy đủ tên tiết khí của 1 năm
    this.get24TietKhiOfYear(tempTime);
    // điều chỉnh lịch dương thành lịch tiết khí(chủ yếu là tháng)
    let dateTietKhi = this.getCorrectTietKhi(tempTime);

    if (Cookies.get("idUser")) {
      await firebase
        .database()
        .ref("/users/" + Cookies.get("idUser")) // check xem có tồn tại chưa
        .once("value")
        .then(function (snapshot) {
          if (snapshot.val()) {
            globalUtils.isLogin = true;
            globalUtils.userInfo = snapshot.val();
          }
        });
    }
    const dateLunar = new Date(date);

    let keyWordSearch = [];
    for (let i = 0; i <= 30; i++) {
      let dateSearch = new Date(
        date?.getFullYear(),
        date.getMonth(),
        date.getDate() + i
      );
      let canChiADay = await DayToCanChi(dateSearch.toDateString());
      let kiengkytrongngay = kiengKy(dateTietKhi.getMonth(), canChiADay);
      keyWordSearch.push(kiengkytrongngay?.nen);
    }

    const thaiDuongThaiAm = this.thaiDuongThaiAm(dateTietKhi.toDateString());
    const ngayKyAmLich = this.getNgayKyAmLich(dateShowBellowSolarDate);
    const ngayKyTietKhi = this.getNgayKyTietKhi(tempTime);

    const isSauDongChi = this.getIsSauDongChi(dateTietKhi.toDateString());
    //star sử dụng dương lịch không sai
    const star = thapNhiBatTu(date);
    const canYear = YearToCan(dateTietKhi.getYear());
    const chiYear = YearToChi(dateTietKhi.getYear());

    const canChiMonth = MonthToCanChi(canYear, dateTietKhi.getMonth());
    const canChiDay = await DayToCanChi(date.toDateString());

    const napAmNgay = getNamAp(canChiDay.name);
    const toaCanChi = Const.Que[toaNumber];
    const namSinhCanChi = Const.Que[namSinhNumber];
    const huongTamSatYear = this.convertTamSatSideOnCompass(chiYear.name);
    let realDate = new Date(dateTietKhi);
    const starYear = this.phiTinhNam(realDate);
    const huongNguHoangYear = this.convertSideToCompass(starYear);
    let starMonth = phiTinhThang(realDate);

    if (realDate?.getMonth() === 0) {
      let tempDate = new Date(
        realDate?.getFullYear() + 1,
        realDate?.getMonth(),
        realDate?.getDate()
      );
      starMonth = phiTinhThang(tempDate);
    }
    const huongNguHoangMonth = this.convertSideToCompass(starMonth);

    let chiMonthLunar = "";
    let huongTamSatMonth = "";
    let huongTamSatDay = "";
    let huongTamSatHour = "";
    let queNgay = null;
    let chiNgay = "";
    let nhiThapKienTru = null;
    let chiHour = null;
    let ngayHoangDao = null;
    let canDay = null;
    const initData = dateData.map((item) => {
      if (item.header === "Năm") {
        item.canChi = canYear.name;
        item.diaChi = chiYear.name;
        item.qua = getQue(canYear.name, chiYear.name).tenque;
        item.que = getQue(canYear.name, chiYear.name);
      } else if (item.header === "Tháng") {
        //tách chuỗi name trong canChiMonth
        let tempCan = canChiMonth.name.split(" ");

        item.canChi = tempCan[0];
        item.diaChi = tempCan[1];
        item.qua = getQue(tempCan[0], tempCan[1]).tenque;
        item.que = getQue(tempCan[0], tempCan[1]);
        huongTamSatMonth = this.convertTamSatSideOnCompass(item.diaChi);
        chiMonthLunar = tempCan[1];
      } else if (item.header === "Ngày") {
        let tempCanDay = canChiDay.name.split(" ");
        item.canChi = tempCanDay[0];
        item.diaChi = tempCanDay[1];
        let chiDate = Const.ChiArr.find((v) => v.name === tempCanDay[1]);
        let canDate = Const.CanArr.find((v) => v.name === tempCanDay[0]);

        nhiThapKienTru = getNhiThapKienTru(
          realDate.getMonth(),
          chiDate.name,
          canDate
        );
        huongTamSatDay = this.convertTamSatSideOnCompass(item.diaChi);

        ngayHoangDao = ngayGioHoangDao(dateTietKhi.getMonth(), chiDate.code);
        item.qua = getQue(tempCanDay[0], tempCanDay[1]).tenque;
        item.que = getQue(tempCanDay[0], tempCanDay[1]);
        queNgay = item.que;
        chiNgay = tempCanDay[1];
      } else if (item.header === "Giờ") {
        let tempCanDay = canChiDay.name.split(" ");
        let tempCanChiHour = HourToCanChi(
          tempCanDay[0],
          date.getHours()
        ).name.split(" ");
        item.canChi = tempCanChiHour[0];
        item.diaChi = tempCanChiHour[1];
        item.qua = getQue(tempCanChiHour[0], tempCanChiHour[1]).tenque;
        item.que = getQue(tempCanChiHour[0], tempCanChiHour[1]);
        huongTamSatHour = this.convertTamSatSideOnCompass(item.diaChi);
        chiHour = tempCanChiHour;
        canDay = tempCanDay[0];
      } else if (item.header === "Tọa") {
        let tempToaCanChi = toaCanChi.queViet.split(" ");
        item.canChi = tempToaCanChi[0];
        item.diaChi = tempToaCanChi[1];
        item.qua = toaCanChi.tenque;
        item.que = getQue(tempToaCanChi[0], tempToaCanChi[1]);
      } else if (item.header.toUpperCase() === "Năm Sinh".toUpperCase()) {
        let tempToaCanChi = namSinhCanChi.queViet.split(" ");
        item.canChi = tempToaCanChi[0];
        item.diaChi = tempToaCanChi[1];
        item.qua = namSinhCanChi.tenque;
        item.que = getQue(tempToaCanChi[0], tempToaCanChi[1]);
      }
      return item;
    });
    const kiengkytrongngay = kiengKy(dateTietKhi.getMonth(), canChiDay);
    const nam = this.kyMonDonGiapThangNam.filter(
      (v) => v.nam === dateTietKhi.getFullYear()
    )[0];

    const result1080cucHour = this.get1080cuc(amDonOrDuongDonGio, chiHour);
    const result1080cucHour_new = this.get1080cucNew(
      amDonOrDuongDonGio,
      chiHour
    );
    const result1080cucDate = this.get1080cuc(
      amDonOrDuongDonNgay,
      canChiDay.name.split(" ")
    );

    const result1080cucMonth = this.get1080cuc(
      nam?.amDonThang,
      canChiMonth.name.split(" ")
    );
    const result1080cucYear = this.get1080cuc(nam?.amDonNam, [
      canYear.name,
      chiYear.name,
    ]);

    this.setState({
      ngayHoangDao,
      chiHour,
      canDay,
      momentHour: date.getHours(),
      huongTamSatHour,
      nhiThapKienTru,
      chiDay: chiNgay,
      momentDate: date.getDate(),
      canChiDay: queNgay,
      huongTamSatMonth,
      huongTamSatDay,
      chiMonthLunar,
      momentMonth: dateLunar.getMonth() + 1,
      momentYear: dateLunar.getYear(),
      queNam: chiYear,
      result1080cucDate,
      result1080cucHour,
      result1080cucHour_new,
      result1080cucMonth,
      result1080cucYear,
      ngayKyAmLich,
      ngayKyTietKhi,
      dateData: initData,
      namSinhCanChi,
      searchSelectMonth: date.getMonth(),
      searchSelectYear: date.getFullYear(),
      realDatePhiTinh: realDate,
      dateTietKhi,
      huongTamSatYear,
      huongNguHoangYear,
      huongNguHoangMonth,
      isSauDongChi,
      napAmNgay,
      dateShowBellowSolarDate,
      star,
      starMonth,
      kiengkytrongngay,
      thaiDuongThaiAm,
      keyWordSearch,
      isLoading: false,
      starDay,
      toaCanChi,
      dateLunar,
      taiThan: canChiDay.taiThan,
      hyThan: canChiDay.hyThan,
    });
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClick);
  }
  handleClick = () => {
    if (this.searchRef.state.openLayoutResult) {
      this.searchRef.setState({ openLayoutResult: false });
    }
  };

  async componentDidMount() {
    const google = (window.google = window.google ? window.google : {});
    const login = this.login;
    window.onload = function () {
      google.accounts.id.initialize({
        client_id:
          "135312632635-uml0mrhqpl0jr9kfpct57clk1j1u1imj.apps.googleusercontent.com",
        callback: login,
      });
      google.accounts.id.renderButton(document.getElementById("signInDiv"), {
        theme: "outline",
        size: "large",
      });
    };

    document.addEventListener("click", this.handleClick);
    await this.checkLogin();
    await this.refreshPage();
    await this.handleKyMon();

  }

  async onChangeData() {
    const { date, dateData, toaNumber, namSinhNumber } = this.state;
    try {
      if (date.getFullYear() > 2025) {
        alert(
          "Thông cảm, tạm thời năm 2024 sẽ thiếu thông tin giờ Kỳ Môn Độn Giáp. Chúng tôi sẽ cập nhật sớm nhất cho bạn."
        );
      }
      let dateTime = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes()
      );

      let dateShowBellowSolarDate = lunar.solar2Lunar(
        date.getDate(),
        date.getMonth() + 1,
        date.getFullYear()
      );
      this.getTietKhiAllYear(dateTime);
      this.get24TietKhiOfYear(dateTime);
      const amDonOrDuongDonGio = this.getAmDonOrDuongDon(dateTime);
      const amDonOrDuongDonNgay = this.getAmDonOrDuongDonTheoNgay(dateTime);

      const correctDateTietKhi = this.getCorrectTietKhi(dateTime);

      const starYear = this.phiTinhNam(correctDateTietKhi);
      const huongNguHoangYear = this.convertSideToCompass(starYear);

      let starMonth = phiTinhThang(correctDateTietKhi);

      if (correctDateTietKhi?.getMonth() === 0) {
        let tempDate = new Date(
          correctDateTietKhi?.getFullYear() + 1,
          correctDateTietKhi?.getMonth(),
          correctDateTietKhi?.getDate()
        );
        starMonth = phiTinhThang(tempDate);
      }

      const huongNguHoangMonth = this.convertSideToCompass(starMonth);

      const thaiDuongThaiAm = this.thaiDuongThaiAm(
        correctDateTietKhi.toDateString()
      );

      const canYear = YearToCan(correctDateTietKhi.getYear());
      const chiYear = YearToChi(correctDateTietKhi.getYear());
      const huongTamSatYear = this.convertTamSatSideOnCompass(chiYear.name);

      const canChiMonth = MonthToCanChi(canYear, correctDateTietKhi.getMonth());

      const starDay = this.getDayStarOfADate(dateTime);
      const isSauDongChi = this.getIsSauDongChi(correctDateTietKhi);

      const canChiDay = await DayToCanChi(date.toDateString());

      const napAmNgay = getNamAp(canChiDay.name);
      const star = thapNhiBatTu(dateTime);
      const ngayKyAmLich = this.getNgayKyAmLich(dateShowBellowSolarDate);
      const ngayKyTietKhi = this.getNgayKyTietKhi(dateTime);
      const toaCanChi = Const.QueSortConGiap[toaNumber];
      const namSinhCanChi = Const.Que[namSinhNumber];
      let realDate = new Date(correctDateTietKhi);
      let result1080cucHour = this.state.result1080cucHour;
      let result1080cucHour_new = this.state.result1080cucHour_new;
      const initData = dateData.map((item) => {
        if (item.header === "Năm") {
          item.canChi = canYear.name;
          item.diaChi = chiYear.name;
          item.qua = getQue(canYear.name, chiYear.name).tenque;
          item.que = getQue(canYear.name, chiYear.name);
          this.setState({ queNam: chiYear });
        } else if (item.header === "Tháng") {
          //tách chuỗi name trong canChiMonth
          let tempCan = canChiMonth.name.split(" ");
          item.canChi = tempCan[0];
          item.diaChi = tempCan[1];
          item.qua = getQue(tempCan[0], tempCan[1]).tenque;
          item.que = getQue(tempCan[0], tempCan[1]);
          const huongTamSatMonth = this.convertTamSatSideOnCompass(item.diaChi);

          this.setState({ chiMonthLunar: tempCan[1], huongTamSatMonth });
        } else if (item.header === "Ngày") {
          let tempCanDay = canChiDay.name.split(" ");
          item.canChi = tempCanDay[0];
          item.diaChi = tempCanDay[1];
          let chiDate = Const.ChiArr.find((v) => v.name === tempCanDay[1]);
          let canDate = Const.CanArr.find((v) => v.name === tempCanDay[0]);
          const huongTamSatDay = this.convertTamSatSideOnCompass(item.diaChi);

          const nhiThapKienTru = getNhiThapKienTru(
            correctDateTietKhi.getMonth(),
            chiDate.name,
            canDate
          );

          let ngayHoangDao = ngayGioHoangDao(
            correctDateTietKhi.getMonth(),
            chiDate.code
          );
          item.qua = getQue(tempCanDay[0], tempCanDay[1]).tenque;
          item.que = getQue(tempCanDay[0], tempCanDay[1]);
          this.setState({
            canChiDay: item.que,
            huongTamSatDay,
            chiDay: tempCanDay[1],
            ngayHoangDao,
            nhiThapKienTru,
          });
        } else if (item.header === "Giờ") {
          let tempCanDay = canChiDay.name.split(" ");
          let tempCanChiHour = HourToCanChi(
            tempCanDay[0],
            dateTime.getHours()
          ).name.split(" ");
          item.canChi = tempCanChiHour[0];
          item.diaChi = tempCanChiHour[1];
          item.qua = getQue(tempCanChiHour[0], tempCanChiHour[1]).tenque;
          item.que = getQue(tempCanChiHour[0], tempCanChiHour[1]);
          const huongTamSatHour = this.convertTamSatSideOnCompass(item.diaChi);

          this.setState(
            {
              chiHour: tempCanChiHour,
              huongTamSatHour,
              canDay: tempCanDay[0],
            },
            () => {
              const result1080cucDate = this.get1080cuc(
                amDonOrDuongDonNgay,
                canChiDay.name.split(" ")
              );
              const nam = this.kyMonDonGiapThangNam.filter(
                (v) => v.nam === correctDateTietKhi.getFullYear()
              )[0];
              const result1080cucMonth = this.get1080cuc(
                nam?.amDonThang,
                canChiMonth.name.split(" ")
              );
              const result1080cucYear = this.get1080cuc(nam?.amDonNam, [
                canYear.name,
                chiYear.name,
              ]);

              result1080cucHour = this.get1080cuc(
                amDonOrDuongDonGio,
                this.state.chiHour
              );
              result1080cucHour_new = this.get1080cucNew(
                amDonOrDuongDonGio,
                this.state.chiHour
              );
              this.setState({
                result1080cucHour,
                result1080cucHour_new,
                result1080cucDate,
                result1080cucMonth,
                result1080cucYear,
              });
            }
          );
        } else if (item.header === "Tọa") {
          let tempToaCanChi = toaCanChi.queViet.split(" ");
          item.canChi = tempToaCanChi[0];
          item.diaChi = tempToaCanChi[1];
          item.qua = toaCanChi.tenque;
          item.que = toaCanChi;
        } else if (item.header.toUpperCase() === "Năm sinh".toUpperCase()) {
          let tempNamSinhCanChi = namSinhCanChi.queViet.split(" ");
          item.canChi = tempNamSinhCanChi[0];
          item.diaChi = tempNamSinhCanChi[1];
          item.qua = namSinhCanChi.tenque;
          item.que = namSinhCanChi;
        }
        return item;
      });
      const kiengkytrongngay = kiengKy(
        correctDateTietKhi.getMonth(),
        canChiDay
      );

      // const result1080cucHour = await this.get1080cuc(amDonOrDuongDon, this.state.chiHour);
      // nếu như giờ là 23 giờ thì sẽ trừ đi 1 ngày
      // if (dateTime.getHours() === 23) {
      //   dateTime = dateTime.setDate(dateTime.getDate() + 1);
      // }
      await this.handleKyMon();
      this.setState({
        ngayKyAmLich,
        ngayKyTietKhi,
        dateData: initData,
        date: new Date(dateTime),
        namSinhCanChi,
        huongTamSatYear,
        dateShowBellowSolarDate,
        dateTietKhi: correctDateTietKhi,
        realDatePhiTinh: realDate,
        toaCanChi,
        huongNguHoangYear,
        huongNguHoangMonth,
        napAmNgay,
        kiengkytrongngay,
        star,
        starDay,
        starMonth,
        isSauDongChi,
        thaiDuongThaiAm,
        taiThan: canChiDay.taiThan,
        hyThan: canChiDay.hyThan,
        dateSave: dateTime,
      });
    } catch (error) {
      alert(error);
      console.log(error);
    }
  }

  changeTime() {
    var x = document.getElementById("Time");
    var i = x.selectedIndex;
    var hourSeted = this.state.date.setHours(x.options[i].value, 0, 0, 0);

    // if (x.selectedIndex === 0) {
    //   //  hourSeted = this.state.date.setHours(0, 0, 0, 0);
    //   hourSeted = this.state.date.setDate(this.state.date.getDate() - 1);
    //   hourSeted = this.state.date.setHours(23, 0, 0, 0);
    // } else {
    // hourSeted = this.state.date.setHours(x.options[i].value, 0, 0, 0);
    // }
    let date = new Date(hourSeted);
    this.setState({ date });
    setTimeout(() => {
      this.onChangeData();
    }, 300);
  }

  onClickNextHour() {
    let hourSetted = this.state.date.setHours(this.state.date.getHours() + 2);

    let dateTemp = new Date(hourSetted);

    if (dateTemp?.getHours() === 23) {
      dateTemp = this.state.date.setDate(this.state.date.getDate() + 1);
    }
    let mainDate = new Date(dateTemp);

    this.setState({ date: mainDate }, () => {
      this.onChangeData();
    });
  }
  onClickBackHour() {
    let hourSetted = this.state.date.setHours(this.state.date.getHours() - 2);
    let dateTemp = new Date(hourSetted);

    if (dateTemp?.getHours() === 21) {
      dateTemp = this.state.date.setDate(this.state.date.getDate() - 1);
    }
    let mainDate = new Date(dateTemp);

    this.setState({ date: mainDate }, () => {
      this.onChangeData();
    });
  }

  onClickNextDate() {
    let daySetted = this.state.date.setDate(this.state.date.getDate() + 1);
    let date = new Date(daySetted);

    this.setState({ date });
    setTimeout(() => {
      this.onChangeData();
    }, 200);
  }
  onClickBackDate() {
    let daySetted = this.state.date.setDate(this.state.date.getDate() - 1);
    let date = new Date(daySetted);

    this.setState({ date });
    setTimeout(() => {
      this.onChangeData();
    }, 200);
  }
  onClickNextMonth() {
    let monthSetted = this.state.date.setMonth(this.state.date.getMonth() + 1);
    let date = new Date(monthSetted);

    this.setState({
      date,
    });
    setTimeout(() => {
      this.onChangeData();
    }, 500);
  }
  onClickBackMonth() {
    let monthSetted = this.state.date.setMonth(this.state.date.getMonth() - 1);
    let date = new Date(monthSetted);

    this.setState({
      date,
    });

    setTimeout(() => {
      this.onChangeData();
    }, 200);
  }
  onClickNextYear() {
    let yearSetted = this.state.date.setFullYear(
      this.state.date?.getFullYear() + 1
    );
    let date = new Date(yearSetted);
    this.setState({
      date,
    });
    setTimeout(() => {
      this.onChangeData();
    }, 200);
  }
  onClickBackYear() {
    let yearSetted = this.state.date.setFullYear(
      this.state.date?.getFullYear() - 1
    );
    let date = new Date(yearSetted);
    this.setState({
      date,
    });
    setTimeout(() => {
      this.onChangeData();
    }, 200);
  }
  onClickNextToaNNamSinh(index, param) {
    if (index >= 63) {
      if (param === "Tọa") {
        this.setState({ toaNumber: 0 });
      } else {
        this.setState({ namSinhNumber: 0 });
      }
    } else {
      if (param === "Tọa") {
        this.setState({ toaNumber: index + 1 });
      } else {
        // Index of Giáp Tý, giáp ngọ, canh thân ,canh dần
        if (index === 0 || index === 32 || index === 56 || index === 27) {
          this.setState({ namSinhNumber: index + 2 });
        } else {
          this.setState({ namSinhNumber: index + 1 });
        }
      }
    }
    setTimeout(() => {
      this.onChangeData();
    }, 200);
  }
  onClickBackToaNNamSinh(index, param) {
    if (index <= 0) {
      if (param === "Tọa") {
        this.setState({ toaNumber: 63 });
      } else {
        this.setState({ namSinhNumber: 63 });
      }
    } else {
      if (param === "Tọa") {
        this.setState({ toaNumber: index - 1 });
      } else {
        if (index === 1 || index === 33 || index === 57 || index === 28) {
          this.setState({ namSinhNumber: index === 1 ? 63 : index - 2 });
        } else {
          this.setState({ namSinhNumber: index - 1 });
        }
      }
    }
    setTimeout(() => {
      this.onChangeData();
    }, 200);
  }
  get24TietKhiOfYear(solarDate) {
    // 1 năm có 24 tiết khí phải lấy ra được tiết khi của mỗi năm.
    // bắt đầu từ năm 1932 vì trong file excel bắt đầu từ năm 1932

    const tempDateTime = new Date(solarDate);
    //file excel bắt đầu từ năm 1932
    const distance = tempDateTime?.getFullYear() - 1932;
    //lấy dòng của ngày đầu tháng tiết khí và ngày cuối tháng tiết khí
    //dòng ngày đầu của tháng lẻ 1 năm
    const dongNgayDauThangLe = distance * 42 + 36;
    //dòng ngày cuối tháng lẻ
    const dongNgayCuoiThangLe = distance * 42 + 37;
    //dòng ngày đầu của tháng chẳn 1 năm
    const dongNgayDauThangChan = distance * 42 + 38;
    //dòng ngày cuối tháng chẳn
    const dongNgayCuoiThangChan = distance * 42 + 39;
    //Mỗi tháng cách nhau 4 column
    // + 1 vì getmonth trong JS tính theo mảng bắt đầu từ 0(vd tháng 1 là 0 tháng 2 là 1)
    // const colThang = tempDateTime.getMonth() + 1 + 4;
    // bao gồm 24 tiết khí
    // lấy ra được thông tin từ excel rồi

    this.tietKhiInYear = [
      this.excelLichVanNien[dongNgayDauThangLe][1].split(" - "),
      this.excelLichVanNien[dongNgayCuoiThangLe][1].split(" - "),
      this.excelLichVanNien[dongNgayDauThangChan][1].split(" - "),
      this.excelLichVanNien[dongNgayCuoiThangChan][1].split(" - "),
      this.excelLichVanNien[dongNgayDauThangLe][5].split(" - "),
      this.excelLichVanNien[dongNgayCuoiThangLe][5].split(" - "),
      this.excelLichVanNien[dongNgayDauThangChan][5].split(" - "),
      this.excelLichVanNien[dongNgayCuoiThangChan][5].split(" - "),
      this.excelLichVanNien[dongNgayDauThangLe][9].split(" - "),
      this.excelLichVanNien[dongNgayCuoiThangLe][9].split(" - "),
      this.excelLichVanNien[dongNgayDauThangChan][9].split(" - "),
      this.excelLichVanNien[dongNgayCuoiThangChan][9].split(" - "),
      this.excelLichVanNien[dongNgayDauThangLe][13].split(" - "),
      this.excelLichVanNien[dongNgayCuoiThangLe][13].split(" - "),
      this.excelLichVanNien[dongNgayDauThangChan][13].split(" - "),
      this.excelLichVanNien[dongNgayCuoiThangChan][13].split(" - "),
      this.excelLichVanNien[dongNgayDauThangLe][17].split(" - "),
      this.excelLichVanNien[dongNgayCuoiThangLe][17].split(" - "),
      this.excelLichVanNien[dongNgayDauThangChan][17].split(" - "),
      this.excelLichVanNien[dongNgayCuoiThangChan][17].split(" - "),
      this.excelLichVanNien[dongNgayDauThangLe][21].split(" - "),
      this.excelLichVanNien[dongNgayCuoiThangLe][21].split(" - "),
      this.excelLichVanNien[dongNgayDauThangChan][21].split(" - "),
      this.excelLichVanNien[dongNgayCuoiThangChan][21].split(" - "),
    ];
  }
  thaiDuongThaiAm(DateTimeLunar) {
    // 1 năm có 24 tiết khí phải lấy ra được tiết khi của mỗi năm.
    // bắt đầu từ năm 1932 vì trong file excel bắt đầu từ năm 1932

    const tempDateTime = new Date(DateTimeLunar);
    //file excel bắt đầu từ năm 1932
    const distance = tempDateTime?.getFullYear() - 1932;
    //lấy dòng của ngày đầu tháng tiết khí và ngày cuối tháng tiết khí
    //dòng ngày đầu của tháng lẻ 1 năm
    const dongNgayDauThangLe = distance * 42 + 36;
    //dòng ngày cuối tháng lẻ
    const dongNgayCuoiThangLe = distance * 42 + 37;
    //dòng ngày đầu của tháng chẳn 1 năm
    const dongNgayDauThangChan = distance * 42 + 38;
    //dòng ngày cuối tháng chẳn
    const dongNgayCuoiThangChan = distance * 42 + 39;
    //Mỗi tháng cách nhau 4 column
    // + 1 vì getmonth trong JS tính theo mảng bắt đầu từ 0(vd tháng 1 là 0 tháng 2 là 1)
    // const colThang = tempDateTime.getMonth() + 1 + 4;
    // bao gồm 24 tiết khí
    // lấy ra được thông tin từ excel rồi
    const cellStringTieuHan = this.excelLichVanNien[dongNgayDauThangLe][1]
      .split("-")[0]
      .split(" ");
    const cellStringDaiHan = this.excelLichVanNien[dongNgayCuoiThangLe][1]
      .split("-")[0]
      .split(" ");
    const cellStringLapXuan = this.excelLichVanNien[dongNgayDauThangChan][1]
      .split("-")[0]
      .split(" ");
    const cellStringVuThuy = this.excelLichVanNien[dongNgayCuoiThangChan][1]
      .split("-")[0]
      .split(" ");
    const cellStringKinhTrap = this.excelLichVanNien[dongNgayDauThangLe][5]
      .split("-")[0]
      .split(" ");
    const cellStringXuanPhan = this.excelLichVanNien[dongNgayCuoiThangLe][5]
      .split("-")[0]
      .split(" ");
    const cellStringThanhMinh = this.excelLichVanNien[dongNgayDauThangChan][5]
      .split("-")[0]
      .split(" ");
    const cellStringCocVu = this.excelLichVanNien[dongNgayCuoiThangChan][5]
      .split("-")[0]
      .split(" ");
    const cellStringLapHa = this.excelLichVanNien[dongNgayDauThangLe][9]
      .split("-")[0]
      .split(" ");
    const cellStringTieuMan = this.excelLichVanNien[dongNgayCuoiThangLe][9]
      .split("-")[0]
      .split(" ");
    const cellStringMangChung = this.excelLichVanNien[dongNgayDauThangChan][9]
      .split("-")[0]
      .split(" ");
    const cellStringHaChi = this.excelLichVanNien[dongNgayCuoiThangChan][9]
      .split("-")[0]
      .split(" ");
    const cellStringTieuThu = this.excelLichVanNien[dongNgayDauThangLe][13]
      .split("-")[0]
      .split(" ");
    const cellStringDaiThu = this.excelLichVanNien[dongNgayCuoiThangLe][13]
      .split("-")[0]
      .split(" ");
    const cellStringLapThu = this.excelLichVanNien[dongNgayDauThangChan][13]
      .split("-")[0]
      .split(" ");
    const cellStringXuThu = this.excelLichVanNien[dongNgayCuoiThangChan][13]
      .split("-")[0]
      .split(" ");
    const cellStringBachLo = this.excelLichVanNien[dongNgayDauThangLe][17]
      .split("-")[0]
      .split(" ");
    const cellStringThuPhan = this.excelLichVanNien[dongNgayCuoiThangLe][17]
      .split("-")[0]
      .split(" ");
    const cellStringHanLo = this.excelLichVanNien[dongNgayDauThangChan][17]
      .split("-")[0]
      .split(" ");
    const cellStringSuongGiang = this.excelLichVanNien[
      dongNgayCuoiThangChan
    ][17]
      .split("-")[0]
      .split(" ");
    const cellStringLapDong = this.excelLichVanNien[dongNgayDauThangLe][21]
      .split("-")[0]
      .split(" ");
    const cellStringTieuTuyet = this.excelLichVanNien[dongNgayCuoiThangLe][21]
      .split("-")[0]
      .split(" ");
    const cellStringDaiTuyet = this.excelLichVanNien[dongNgayDauThangChan][21]
      .split("-")[0]
      .split(" ");
    const cellStringDongChi = this.excelLichVanNien[dongNgayCuoiThangChan][21]
      .split("-")[0]
      .split(" ");
    this.dataNhiTuTietKhi = [
      this.excelLichVanNien[dongNgayDauThangLe][1].split(" - "),
      this.excelLichVanNien[dongNgayCuoiThangLe][1].split(" - "),
      this.excelLichVanNien[dongNgayDauThangChan][1].split(" - "),
      this.excelLichVanNien[dongNgayCuoiThangChan][1].split(" - "),
      this.excelLichVanNien[dongNgayDauThangLe][5].split(" - "),
      this.excelLichVanNien[dongNgayCuoiThangLe][5].split(" - "),
      this.excelLichVanNien[dongNgayDauThangChan][5].split(" - "),
      this.excelLichVanNien[dongNgayCuoiThangChan][5].split(" - "),
      this.excelLichVanNien[dongNgayDauThangLe][9].split(" - "),
      this.excelLichVanNien[dongNgayCuoiThangLe][9].split(" - "),
      this.excelLichVanNien[dongNgayDauThangChan][9].split(" - "),
      this.excelLichVanNien[dongNgayCuoiThangChan][9].split(" - "),
      this.excelLichVanNien[dongNgayDauThangLe][13].split(" - "),
      this.excelLichVanNien[dongNgayCuoiThangLe][13].split(" - "),
      this.excelLichVanNien[dongNgayDauThangChan][13].split(" - "),
      this.excelLichVanNien[dongNgayCuoiThangChan][13].split(" - "),
      this.excelLichVanNien[dongNgayDauThangLe][17].split(" - "),
      this.excelLichVanNien[dongNgayCuoiThangLe][17].split(" - "),
      this.excelLichVanNien[dongNgayDauThangChan][17].split(" - "),
      this.excelLichVanNien[dongNgayCuoiThangChan][17].split(" - "),
      this.excelLichVanNien[dongNgayDauThangLe][21].split(" - "),
      this.excelLichVanNien[dongNgayCuoiThangLe][21].split(" - "),
      this.excelLichVanNien[dongNgayDauThangChan][21].split(" - "),
      this.excelLichVanNien[dongNgayCuoiThangChan][21].split(" - "),
    ];
    const dateTimeTieuHan = new Date(
      tempDateTime?.getFullYear(),
      cellStringTieuHan[0].split("/")[1] - 1,
      cellStringTieuHan[0].split("/")[0],
      cellStringTieuHan[1].split(":")[0],
      cellStringTieuHan[1].split(":")[1]
    );
    const dateTimeDaiHan = new Date(
      tempDateTime?.getFullYear(),
      cellStringDaiHan[0].split("/")[1] - 1,
      cellStringDaiHan[0].split("/")[0],
      cellStringDaiHan[1].split(":")[0],
      cellStringDaiHan[1].split(":")[1]
    );
    const dateTimeLapXuan = new Date(
      tempDateTime?.getFullYear(),
      cellStringLapXuan[0].split("/")[1] - 1,
      cellStringLapXuan[0].split("/")[0],
      cellStringLapXuan[1].split(":")[0],
      cellStringLapXuan[1].split(":")[1]
    );
    const dateTimeVuThuy = new Date(
      tempDateTime?.getFullYear(),
      cellStringVuThuy[0].split("/")[1] - 1,
      cellStringVuThuy[0].split("/")[0],
      cellStringVuThuy[1].split(":")[0],
      cellStringVuThuy[1].split(":")[1]
    );
    const dateTimeKinhTrap = new Date(
      tempDateTime?.getFullYear(),
      cellStringKinhTrap[0].split("/")[1] - 1,
      cellStringKinhTrap[0].split("/")[0],
      cellStringKinhTrap[1].split(":")[0],
      cellStringKinhTrap[1].split(":")[1]
    );
    const dateTimeXuanPhan = new Date(
      tempDateTime?.getFullYear(),
      cellStringXuanPhan[0].split("/")[1] - 1,
      cellStringXuanPhan[0].split("/")[0],
      cellStringXuanPhan[1].split(":")[0],
      cellStringXuanPhan[1].split(":")[1]
    );
    const dateTimeThanhMinh = new Date(
      tempDateTime?.getFullYear(),
      cellStringThanhMinh[0].split("/")[1] - 1,
      cellStringThanhMinh[0].split("/")[0],
      cellStringThanhMinh[1].split(":")[0],
      cellStringThanhMinh[1].split(":")[1]
    );
    const dateTimeCocVu = new Date(
      tempDateTime?.getFullYear(),
      cellStringCocVu[0].split("/")[1] - 1,
      cellStringCocVu[0].split("/")[0],
      cellStringCocVu[1].split(":")[0],
      cellStringCocVu[1].split(":")[1]
    );
    const dateTimeLapHa = new Date(
      tempDateTime?.getFullYear(),
      cellStringLapHa[0].split("/")[1] - 1,
      cellStringLapHa[0].split("/")[0],
      cellStringLapHa[1].split(":")[0],
      cellStringLapHa[1].split(":")[1]
    );
    const dateTimeTieuMan = new Date(
      tempDateTime?.getFullYear(),
      cellStringTieuMan[0].split("/")[1] - 1,
      cellStringTieuMan[0].split("/")[0],
      cellStringTieuMan[1].split(":")[0],
      cellStringTieuMan[1].split(":")[1]
    );
    const dateTimeMangChung = new Date(
      tempDateTime?.getFullYear(),
      cellStringMangChung[0].split("/")[1] - 1,
      cellStringMangChung[0].split("/")[0],
      cellStringMangChung[1].split(":")[0],
      cellStringMangChung[1].split(":")[1]
    );
    const dateTimeHaChi = new Date(
      tempDateTime?.getFullYear(),
      cellStringHaChi[0].split("/")[1] - 1,
      cellStringHaChi[0].split("/")[0],
      cellStringHaChi[1].split(":")[0],
      cellStringHaChi[1].split(":")[1]
    );
    const dateTimeTieuThu = new Date(
      tempDateTime?.getFullYear(),
      cellStringTieuThu[0].split("/")[1] - 1,
      cellStringTieuThu[0].split("/")[0],
      cellStringTieuThu[1].split(":")[0],
      cellStringTieuThu[1].split(":")[1]
    );
    const dateTimeDaiThu = new Date(
      tempDateTime?.getFullYear(),
      cellStringDaiThu[0].split("/")[1] - 1,
      cellStringDaiThu[0].split("/")[0],
      cellStringDaiThu[1].split(":")[0],
      cellStringDaiThu[1].split(":")[1]
    );
    const dateTimeLapThu = new Date(
      tempDateTime?.getFullYear(),
      cellStringLapThu[0].split("/")[1] - 1,
      cellStringLapThu[0].split("/")[0],
      cellStringLapThu[1].split(":")[0],
      cellStringLapThu[1].split(":")[1]
    );
    const dateTimeXuThu = new Date(
      tempDateTime?.getFullYear(),
      cellStringXuThu[0].split("/")[1] - 1,
      cellStringXuThu[0].split("/")[0],
      cellStringXuThu[1].split(":")[0],
      cellStringXuThu[1].split(":")[1]
    );
    const dateTimeBachLo = new Date(
      tempDateTime?.getFullYear(),
      cellStringBachLo[0].split("/")[1] - 1,
      cellStringBachLo[0].split("/")[0],
      cellStringBachLo[1].split(":")[0],
      cellStringBachLo[1].split(":")[1]
    );
    const dateTimeThuPhan = new Date(
      tempDateTime?.getFullYear(),
      cellStringThuPhan[0].split("/")[1] - 1,
      cellStringThuPhan[0].split("/")[0],
      cellStringThuPhan[1].split(":")[0],
      cellStringThuPhan[1].split(":")[1]
    );
    const dateTimeHanLo = new Date(
      tempDateTime?.getFullYear(),
      cellStringHanLo[0].split("/")[1] - 1,
      cellStringHanLo[0].split("/")[0],
      cellStringHanLo[1].split(":")[0],
      cellStringHanLo[1].split(":")[1]
    );
    const dateTimeSuongGiang = new Date(
      tempDateTime?.getFullYear(),
      cellStringSuongGiang[0].split("/")[1] - 1,
      cellStringSuongGiang[0].split("/")[0],
      cellStringSuongGiang[1].split(":")[0],
      cellStringSuongGiang[1].split(":")[1]
    );
    const dateTimeLapDong = new Date(
      tempDateTime?.getFullYear(),
      cellStringLapDong[0].split("/")[1] - 1,
      cellStringLapDong[0].split("/")[0],
      cellStringLapDong[1].split(":")[0],
      cellStringLapDong[1].split(":")[1]
    );
    const dateTimeTieuTuyet = new Date(
      tempDateTime?.getFullYear(),
      cellStringTieuTuyet[0].split("/")[1] - 1,
      cellStringTieuTuyet[0].split("/")[0],
      cellStringTieuTuyet[1].split(":")[0],
      cellStringTieuTuyet[1].split(":")[1]
    );
    const dateTimeDaiTuyet = new Date(
      tempDateTime?.getFullYear(),
      cellStringDaiTuyet[0].split("/")[1] - 1,
      cellStringDaiTuyet[0].split("/")[0],
      cellStringDaiTuyet[1].split(":")[0],
      cellStringDaiTuyet[1].split(":")[1]
    );
    const dateTimeDongChi = new Date(
      tempDateTime?.getFullYear(),
      cellStringDongChi[0].split("/")[1] - 1,
      cellStringDongChi[0].split("/")[0],
      cellStringDongChi[1].split(":")[0],
      cellStringDongChi[1].split(":")[1]
    );

    if (tempDateTime.getTime() < dateTimeTieuHan.getTime()) {
      return { thaiDuong: Const.Can.Qui.name, thaiAm: Const.Can.Qui.name };
    } else if (
      tempDateTime.getTime() > dateTimeDaiHan.getTime() &&
      tempDateTime.getTime() < dateTimeLapXuan.getTime()
    ) {
      return { thaiDuong: Const.Chi.Ty.name, thaiAm: Const.Chi.Suu.name };
    } else if (
      tempDateTime.getTime() > dateTimeLapXuan.getTime() &&
      tempDateTime.getTime() < dateTimeVuThuy.getTime()
    ) {
      return { thaiDuong: Const.Can.Nham.name, thaiAm: "Cấn" };
    } else if (
      tempDateTime.getTime() > dateTimeVuThuy.getTime() &&
      tempDateTime.getTime() < dateTimeKinhTrap.getTime()
    ) {
      return { thaiDuong: Const.Chi.Hoi.name, thaiAm: Const.Chi.Dan.name };
    } else if (
      tempDateTime.getTime() > dateTimeKinhTrap.getTime() &&
      tempDateTime.getTime() < dateTimeXuanPhan.getTime()
    ) {
      return { thaiDuong: "Càn", thaiAm: Const.Can.Giap.name };
    } else if (
      tempDateTime.getTime() > dateTimeXuanPhan.getTime() &&
      tempDateTime.getTime() < dateTimeThanhMinh.getTime()
    ) {
      return { thaiDuong: Const.Chi.Tuat.name, thaiAm: Const.Chi.Mao.name };
    } else if (
      tempDateTime.getTime() > dateTimeThanhMinh.getTime() &&
      tempDateTime.getTime() < dateTimeCocVu.getTime()
    ) {
      return { thaiDuong: Const.Can.Tan.name, thaiAm: Const.Can.At.name };
    } else if (
      tempDateTime.getTime() > dateTimeCocVu.getTime() &&
      tempDateTime.getTime() < dateTimeLapHa.getTime()
    ) {
      return { thaiDuong: Const.Chi.Dau.name, thaiAm: Const.Chi.Thin.name };
    } else if (
      tempDateTime.getTime() > dateTimeLapHa.getTime() &&
      tempDateTime.getTime() < dateTimeTieuMan.getTime()
    ) {
      return { thaiDuong: Const.Can.Canh.name, thaiAm: "Tốn" };
    } else if (
      tempDateTime.getTime() > dateTimeTieuMan.getTime() &&
      tempDateTime.getTime() < dateTimeMangChung.getTime()
    ) {
      return { thaiDuong: Const.Chi.Than.name, thaiAm: Const.Chi.Ti.name };
    } else if (
      tempDateTime.getTime() > dateTimeMangChung.getTime() &&
      tempDateTime.getTime() < dateTimeHaChi.getTime()
    ) {
      return { thaiDuong: "Khôn", thaiAm: Const.Can.Binh.name };
    } else if (
      tempDateTime.getTime() > dateTimeHaChi.getTime() &&
      tempDateTime.getTime() < dateTimeTieuThu.getTime()
    ) {
      return { thaiDuong: Const.Chi.Mui.name, thaiAm: Const.Chi.Ngo.name };
    } else if (
      tempDateTime.getTime() > dateTimeTieuThu.getTime() &&
      tempDateTime.getTime() < dateTimeDaiThu.getTime()
    ) {
      return { thaiDuong: Const.Can.Dinh.name, thaiAm: Const.Can.Dinh.name };
    } else if (
      tempDateTime.getTime() > dateTimeDaiThu.getTime() &&
      tempDateTime.getTime() < dateTimeLapThu.getTime()
    ) {
      return { thaiDuong: Const.Chi.Ngo.name, thaiAm: Const.Chi.Mui.name };
    } else if (
      tempDateTime.getTime() > dateTimeLapThu.getTime() &&
      tempDateTime.getTime() < dateTimeXuThu.getTime()
    ) {
      return { thaiDuong: Const.Can.Binh.name, thaiAm: "Khôn" };
    } else if (
      tempDateTime.getTime() > dateTimeXuThu.getTime() &&
      tempDateTime.getTime() < dateTimeBachLo.getTime()
    ) {
      return { thaiDuong: Const.Chi.Ti.name, thaiAm: Const.Chi.Than.name };
    } else if (
      tempDateTime.getTime() > dateTimeBachLo.getTime() &&
      tempDateTime.getTime() < dateTimeThuPhan.getTime()
    ) {
      return { thaiDuong: "Tốn", thaiAm: Const.Can.Canh.name };
    } else if (
      tempDateTime.getTime() > dateTimeThuPhan.getTime() &&
      tempDateTime.getTime() < dateTimeHanLo.getTime()
    ) {
      return { thaiDuong: Const.Chi.Thin.name, thaiAm: Const.Chi.Dau.name };
    } else if (
      tempDateTime.getTime() > dateTimeHanLo.getTime() &&
      tempDateTime.getTime() < dateTimeSuongGiang.getTime()
    ) {
      return { thaiDuong: Const.Can.At.name, thaiAm: "Tân" };
    } else if (
      tempDateTime.getTime() > dateTimeSuongGiang.getTime() &&
      tempDateTime.getTime() < dateTimeLapDong.getTime()
    ) {
      return { thaiDuong: Const.Chi.Mao.name, thaiAm: Const.Chi.Tuat.name };
    } else if (
      tempDateTime.getTime() > dateTimeLapDong.getTime() &&
      tempDateTime.getTime() < dateTimeTieuTuyet.getTime()
    ) {
      return { thaiDuong: Const.Can.Giap.name, thaiAm: "Càn" };
    } else if (
      tempDateTime.getTime() > dateTimeTieuTuyet.getTime() &&
      tempDateTime.getTime() < dateTimeDaiTuyet.getTime()
    ) {
      return { thaiDuong: Const.Chi.Dan.name, thaiAm: Const.Chi.Hoi.name };
    } else if (
      tempDateTime.getTime() > dateTimeDaiTuyet.getTime() &&
      tempDateTime.getTime() < dateTimeDongChi.getTime()
    ) {
      return { thaiDuong: "Cấn", thaiAm: Const.Can.Nham.name };
    } else {
      return { thaiDuong: Const.Chi.Suu.name, thaiAm: Const.Chi.Ty.name };
    }
  }

  async SearchResult(keyword, month, year) {
    const tempTime = new Date(year, 3, 1);
    this.getTietKhiAllYear(tempTime);

    try {
      let keyWordSearch = [];
      for (let i = 0; i <= 30; i++) {
        let date = new Date(year, month, 1 + i);
        let dateTietKhi = this.getCorrectTietKhi(date);
        let canChiADay = await DayToCanChi(date.toDateString());
        let kiengkytrongngay = await kiengKy(
          dateTietKhi.getMonth(),
          canChiADay
        );
        keyWordSearch.push({
          content: kiengkytrongngay?.nen,
          dateResult: date.toDateString(),
        });
      }
      let addIndexEachItem = keyWordSearch.map((value, index) => {
        const info = value.content;
        const day = value.dateResult;
        return { info, day };
      });

      const allResult = addIndexEachItem.map((v) => {
        return {
          content: v.info,
          dateResult: v.day,
        };
      });
      const resultWithKeyWord = addIndexEachItem.filter((v) =>
        v.info.includes(keyword.value)
      );
      const result = resultWithKeyWord.map((v) => {
        return {
          content: v.info,
          dateResult: v.day,
        };
      });
      this.setState({
        searchResult: !keyword.value ? allResult : result,
        searchParam: keyword,
        characterSearch: keyword.value,
      });
    } catch (error) { }
  }

  getIndexOfAQueByStt(stt) {
    return Const.Que.findIndex((e) => e.stt === stt);
  }

  namSinhChange = (namsinh) => {
    var getValue = document.getElementById("namSinhGiaChu");
    const value = getValue.value;
    if (value.length === 4) {
      const yearOfBirth = new Date(value, 1, 2);
      const canYearBorn = YearToCan(value);
      const chiYearBorn = YearToChi(yearOfBirth.getYear());
      const canChiOfBirth = canYearBorn.name + " " + chiYearBorn.name;

      const result = Const.Que.filter((v) => v.queViet === canChiOfBirth);
      const index = this.getIndexOfAQueByStt(result[0].stt);

      let namSinhNumber = 0;
      if (index === 0 || index === 32 || index === 56 || index === 27) {
        namSinhNumber = index - 1;
      } else {
        namSinhNumber = index;
      }
      this.setState({ namSinhNumber: index, namSinhReal: namsinh }, () => {
        this.onChangeData();
      });
    }
  };
  onChangeSearchDate() {
    var m = document.getElementById("SearchMonth");
    var y = document.getElementById("SearchYear");
    var year = y.options[y.selectedIndex].value;
    var month = m.selectedIndex;
    this.SearchResult(this.state.searchParam, month, Number(year));
    this.setState({ searchSelectMonth: month, searchSelectYear: Number(year) });
  }
  onClickYesterday() {
    const today = new Date();
    const daySetted = this.state.date.setDate(today.getDate() - 1);
    const date = new Date(daySetted);
    this.setState({ date });
    setTimeout(() => {
      this.onChangeData();
    }, 500);
  }
  onClickTomorrow() {
    const today = new Date();
    const daySetted = this.state.date.setDate(today.getDate() + 1);
    const date = new Date(daySetted);
    this.setState({ date });
    setTimeout(() => {
      this.onChangeData();
    }, 500);
  }
  onClickToday() {
    const today = new Date();
    const daySetted = this.state.date.setDate(today.getDate());
    const date = new Date(daySetted);
    this.setState({ date });
    setTimeout(() => {
      this.onChangeData();
    }, 500);
  }

  handleChangeName(value) {
    this.setState({ hoTenUser: value });
  }
  render() {
    const {
      result1080cucHour,
      result1080cucHour_new,
      result1080cucHourMaoSon,
      result1080cucDate,
      result1080cucMonth,
      result1080cucYear,
      ngayHoangDao,
      huongTamSatDay,
      huongTamSatMonth,
      huongTamSatYear,
      queNam,
      thaiDuongThaiAm,
      hourDate,
      canDay,
      chiDay,
      huongNguHoangYear,
      isSauDongChi,
      chiMonthLunar,
      starDay,
      huongNguHoangMonth,
      star,
      dateTietKhi,
      napAmNgay,
      kiengkytrongngay,
      nhiThapKienTru,
      date,
      isLoading,
      searchSelectYear,
      searchParam,
      searchResult,
      searchSelectMonth,
      isActive,
      keyWordSearch,
      ngayKyAmLich,
      ngayKyTietKhi,
      dateShowBellowSolarDate,
      dateData,
      canChiDay,
      taiThan,
      hyThan,
      toaCanChi,
      huongTamSatHour,
    } = this.state;
    // chiDay && isSauDongChi && chiMonthLunar && result1080cucHour
    let possibleYear = 2036;
    let possibleYearArr = [];
    for (let i = 1900; i <= possibleYear; i++) {
      possibleYearArr.push(i);
    }
    let monthInYear = [];
    for (let index = 0; index < 12; index++) {
      monthInYear.push(index);
    }
    const hoangDao = ngayHoangDao ? ngayHoangDao : "";
    const starC = star ? star : "";
    const napAm = napAmNgay ? napAmNgay.napAm : "";
    const kiengKy = kiengkytrongngay ? kiengkytrongngay : "";
    const nhiThapKienTruAndDongCong = nhiThapKienTru ? nhiThapKienTru : "";
    const dongCong = nhiThapKienTruAndDongCong?.dongCong;
    const dateC = new Date(date);
    const month = dateC.getMonth();

    const showTietKhiDauThang = this.tietKhiInYear[month * 2] || [];

    const showTietKhiGiuaThang = this.tietKhiInYear[month * 2 + 1] || [];

    const getHour = new Date();

    return (
      <div>
        <div>
          {this.renderModalErrorPressCode(
            this.state.modalErrorCode,
            () => this.setState({ modalErrorCode: false }),
            "Mã code của bạn nhập sai, hệ thống chúng tôi phân biệt chữ hoa và chữ thường vì vậy vui lòng kiểm tra la mã code và thử lại."
          )}
          {this.renderModalErrorPressCode(
            this.state.modalUsedCode,
            () => this.setState({ modalUsedCode: false }),
            "Mã code của bạn đã được sử dụng vui lòng kiểm tra lại mã Code hoặc Liên hệ với chúng tôi để được hỗ trợ thêm."
          )}
          {this.renderModalErrorPressCode(
            this.state.modalSuccessCode,
            () =>
              this.setState({ modalSuccessCode: false }, () =>
                window.location.reload(false)
              ),
            "Nhập Code thành công."
          )}
          {this.renderModalErrorPressCode(
            this.state.modalEmptyCode,
            () => this.setState({ modalEmptyCode: false }),
            "Xin vui lòng nhập mã code trước khi nhấn gửi!!"
          )}
          {this.renderModalErrorPressCode(
            this.state.modalSaveData,
            () => this.setState({ modalSaveData: false }),
            "Lưu thành công"
          )}
          {this.renderModalErrorPressCode(
            this.state.modalDeleteData,
            () => this.setState({ modalDeleteData: false }),
            "Xóa thành công"
          )}
          {this.renderHistoryModal(this.state.modalHistory, () =>
            this.setState({ modalHistory: false })
          )}
          {this.renderModalNote(this.state.modalNote, () =>
            this.setState({ modalNote: false })
          )}
          <Modal open={this.state.modalPressCode}>
            <Modal.Content>
              <div>
                <p style={{ marginBottom: "10px" }}>
                  {`Xin chào, ${this.state.userName}. Dưới đây là thông tin sử dụng WebSite của chúng
tôi, sau thông tin này bạn có thể liên hệ với chúng tôi để có thể sử
dụng web một cách toàn vẹn nhất có thể bạn nhé!!!! Xin mời bạn nhập
Code dưới này và nhấn nút gửi đi để có thể sử dụng web`}
                </p>
                <Input
                  id="redeemCode"
                  size="small"
                  placeholder="Nhập Code..."
                  type="string"
                  onChange={(c) => { }}
                />
                <Button
                  onClick={() => {
                    var code = document.getElementById("redeemCode");
                    if (code?.value !== "") {
                      this.onSendRedeemCode(code?.value);
                    } else {
                      this.setState({ modalEmptyCode: true });
                    }
                  }}
                >
                  Gửi đi
                </Button>
              </div>
            </Modal.Content>
            <Modal.Actions>
              <Button
                onClick={() => this.setState({ modalPressCode: false })}
                primary
              >
                Đóng
              </Button>
            </Modal.Actions>
          </Modal>
          <Modal open={this.state.modalPressSearchCode}>
            <Modal.Content>
              <div>
                <p style={{ marginBottom: "10px" }}>
                  {`Xin chào, bạn muốn sử dụng chức năng này vui lòng nhập code, nếu chưa có code vui lòng liên hệ với chúng tôi để có thông tin chi tiết về chức năng này nhé.`}
                </p>
                <Input
                  id="redeemSearchCode"
                  size="small"
                  placeholder="Nhập Code..."
                  type="string"
                  onChange={(c) => { }}
                />
                <Button
                  onClick={() => {
                    var code = document.getElementById("redeemSearchCode");
                    if (code?.value !== "") {
                      this.onSendRedeemSearchCode(code?.value);
                    } else {
                      this.setState({ modalEmptyCode: true });
                    }
                  }}
                >
                  Gửi đi
                </Button>
              </div>
            </Modal.Content>
            <Modal.Actions>
              <Button
                onClick={() => this.setState({ modalPressSearchCode: false })}
                primary
              >
                Đóng
              </Button>
            </Modal.Actions>
          </Modal>

          <table className="center-header">
            <tbody>
              <tr className="to-hide-print">
                <td>
                  <div>
                    <table>
                      <tbody>
                        <tr height="70px">
                          <td width="10%">
                            <img
                              alt="logo"
                              src={Logo}
                              height="66px"
                              width="66px"
                            />
                          </td>
                          <td width="39%">
                            <h1 id="web-name">TAM NGUYÊN HUYỀN KHÔNG</h1>
                            <h2 id="slogan-web">
                              Huyền Không Đại Quái Trạch Nhật
                            </h2>
                          </td>
                          <td>
                            <table>
                              <tbody>
                                <tr>
                                  <td>
                                    <img
                                      src={CHUONGTRINHGIANGDAY01}
                                      alt="Học viện phong thủy"
                                      width="45px"
                                      height="56px"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      src={CHUONGTRINHGIANGDAY02}
                                      alt="Học viện tam hợp"
                                      width="45px"
                                      height="56px"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      src={CHUONGTRINHGIANGDAY03}
                                      alt="Học viện lục pháp"
                                      width="45px"
                                      height="56px"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      src={CHUONGTRINHGIANGDAY04}
                                      alt="Học viện đại quái"
                                      width="45px"
                                      height="56px"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      src={CHUONGTRINHGIANGDAY05}
                                      alt="Học viện phi tinh"
                                      width="45px"
                                      height="56px"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      src={CHUONGTRINHGIANGDAY06}
                                      alt="Học viện nhân tướng"
                                      width="45px"
                                      height="56px"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      src={CHUONGTRINHGIANGDAY07}
                                      alt="Học viện kinh dịch"
                                      width="45px"
                                      height="56px"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      src={CHUONGTRINHGIANGDAY08}
                                      alt="Học viện chiêm tinh"
                                      width="45px"
                                      height="56px"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      src={CHUONGTRINHGIANGDAY09}
                                      alt="Học viện kỳ môn"
                                      width="45px"
                                      height="56px"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      src={CHUONGTRINHGIANGDAY10}
                                      alt="Học viện trạch nhật"
                                      width="45px"
                                      height="56px"
                                    />
                                  </td>
                                  <td>
                                    <img
                                      src={CHUONGTRINHGIANGDAY11}
                                      alt="Học viện bát tự"
                                      width="45px"
                                      height="56px"
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td>
                            <div
                              style={{
                                float: "right",
                                flexDirection: "row",
                                display: "flex",
                              }}
                            >
                              <Modal
                                trigger={
                                  <Button
                                    style={{
                                      padding: "5px",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <p style={{ lineBreak: "unset" }}>
                                      Thông tin Website
                                    </p>
                                  </Button>
                                }
                              >
                                <Modal.Content>
                                  <div>
                                    <Image
                                      size="massive"
                                      src={thongTinKhoaHoc}
                                    />
                                  </div>
                                </Modal.Content>
                              </Modal>
                              <Button icon onClick={() => window.print()}>
                                <i
                                  className="fa fa-print"
                                  aria-hidden="true"
                                ></i>
                              </Button>
                              {this.state.isHistory ? (
                                <>
                                  <Button
                                    icon
                                    onClick={() => this.handleGetData()}
                                  >
                                    <i
                                      className="fa fa-history"
                                      aria-hidden="true"
                                    ></i>
                                  </Button>
                                  <Button
                                    icon
                                    onClick={() => this.handleSaveData()}
                                  >
                                    <i
                                      className="fa fa-floppy-o"
                                      aria-hidden="true"
                                    ></i>
                                  </Button>
                                  <Button
                                    icon
                                    onClick={() =>
                                      this.setState({ modalNote: true })
                                    }
                                  >
                                    <i
                                      className="fa fa-sticky-note"
                                      aria-hidden="true"
                                    ></i>
                                  </Button>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="4">
                            <div style={{ display: "flex" }}>
                              {!this.props.isAutoSearch && (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Button
                                      onClick={() => {
                                        const getObj =
                                          localStorage.getItem(mdfive);
                                        const dataSavedStorage =
                                          JSON.parse(getObj);
                                        if (dataSavedStorage?.activeSearch) {
                                          this.props.history.push(
                                            "/SearchPage"
                                          ); //
                                        } else {
                                          this.setState({
                                            modalPressSearchCode: true,
                                          });
                                        }
                                      }}
                                      style={{
                                        marginRight: "20px",
                                        backgroundColor: "red",
                                        color: "white",
                                      }}
                                    >
                                      TÌM KIẾM TỰ ĐỘNG
                                    </Button>
                                    <Button
                                      onClick={() => {
                                        const getObj =
                                          localStorage.getItem(mdfive);
                                        const dataSavedStorage =
                                          JSON.parse(getObj);
                                        if (dataSavedStorage?.activeSearch) {
                                          const hourtemp = convertHour(
                                            this.state.date.getHours()
                                          );
                                          const titleEvent = `Nội dung sự kiện`;
                                          let fromTime = new Date(
                                            this.state.date.setHours(
                                              hourtemp.value - 1,
                                              0
                                            )
                                          );
                                          let toTime = new Date(
                                            this.state.date.setHours(
                                              hourtemp.value + 1,
                                              0
                                            )
                                          );
                                          if (fromTime.getHours() === 23) {
                                            toTime = new Date(
                                              this.state.date.setDate(
                                                this.state.date.getDate() + 1
                                              )
                                            );
                                          }
                                          window.open(
                                            `https://calendar.google.com/calendar/u/0/r/eventedit?text=${titleEvent}&dates=${fromTime
                                              .toISOString()
                                              .replace(/[-:.]/g, "")}/${toTime
                                                .toISOString()
                                                .replace(
                                                  /[-:.]/g,
                                                  ""
                                                )}&details=Thông+tin+chi+tiết+tại:+http://huyenkhongtamnguyen.com&location=Ho+Chi+Minh+City,+Vietnam&sf=true&output=xml`
                                          );
                                        } else {
                                          this.setState({
                                            modalPressSearchCode: true,
                                          });
                                        }
                                      }}
                                      style={{
                                        marginRight: "auto",
                                        backgroundColor: "red",
                                        color: "white",
                                      }}
                                    >
                                      Thêm vào lịch Google
                                    </Button>
                                  </div>
                                  <div
                                    style={{
                                      height: "30px",
                                      marginTop: "5px",
                                      marginRight: "30px",
                                    }}
                                  ></div>
                                </div>
                              )}
                              {globalUtils.isLogin ? (
                                <div
                                  style={{
                                    display: "flex",
                                    marginLeft: "auto",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <Button.Group color="blue">
                                      <Button>{` Xin chào, ${this.state.userName}`}</Button>
                                      <Dropdown
                                        style={{ height: "100%" }}
                                        className="button icon"
                                        trigger={<></>}
                                      >
                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            onClick={() =>
                                              this.setState({
                                                modalPressCode: true,
                                              })
                                            }
                                          >
                                            Nhập code
                                          </Dropdown.Item>
                                          <Dropdown.Item onClick={this.logout}>
                                            Đăng xuất
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </Button.Group>
                                  </div>
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "120%",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {this.state.exprireDate
                                      ? `Tài khoản của bạn sẽ hết hạn vào Ngày ${this.state.exprireDate?.getDate()}
                                        Tháng ${this.state.exprireDate?.getMonth() + 1
                                      } Năm ${this.state.exprireDate?.getFullYear()}`
                                      : "Tài khoản của bạn đã hết hạn"}
                                  </p>
                                </div>
                              ) : (
                                <div style={{ marginLeft: "auto" }}>
                                  {globalUtils.isLogin}
                                  <div id="signInDiv"></div>
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="App" ref={this.wrapperRef}>
          {/* Preload image */}
          <Modal basic open={isLoading} size="large">
            <Modal.Content>
              <img
                id="LoadingImage"
                src={Logo}
                alt="logo"
                style={{
                  animation: `spin 10s linear infinite`,
                  justifyContent: "center",
                  alignSelf: "center",
                }}
              />
            </Modal.Content>
          </Modal>
          <p className="to-show-print" id="link-domain">
            https://huyenkhongtamnguyen.com/
          </p>
          <div
            id="Main-Body"
          // style={{ backgroundImage: `url(${Logo})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }}
          >
            <section className="to-hide-print">
              <HeaderHomePage
                changeHour={() => this.changeTime()}
                handleChangeSearch={(value) => this.handleChangeSearch(value)}
                onSelectPossibleMonth={() => this.onChangeSearchDate()}
                onSelectPossibleYear={() => this.onChangeSearchDate()}
                searchSelectYear={searchSelectYear}
                resultSearchKeyword={searchResult}
                searchSelectMonth={searchSelectMonth}
                searchParam={searchParam}
                date={date}
                onChangeDate={this.handleChange}
                isCustomer={isActive}
                searchResult={(keyword) =>
                  this.SearchResult(
                    keyword,
                    date.getMonth(),
                    date.getFullYear()
                  )
                }
                keyWordSearch={keyWordSearch}
                searchRef={(input) => {
                  this.searchRef = input;
                }}
                clickTomorrow={() => this.onClickTomorrow()}
                clickToday={() => this.onClickToday()}
                clickYesterday={() => this.onClickYesterday()}
                changeName={(value) => this.handleChangeName(value)}
                name={this.state.hoTenUser}
                possibleYearArr={possibleYearArr}
                monthInYear={monthInYear}
              ></HeaderHomePage>
            </section>
            <section>
              {showTietKhiDauThang && showTietKhiGiuaThang && (
                <div style={{ flexDirection: "row" }}>
                  <p
                    style={{
                      position: "absolute",
                      margin: "5px",
                      marginBottom: "20px",
                      fontWeight: "bold",
                      width: "900px",
                      fontFamily: "Times New Roman",
                      display: "flex",
                    }}
                  >
                    {showTietKhiDauThang.length > 0
                      ? ` Tiết Khí: ${showTietKhiDauThang[1]} ${showTietKhiDauThang[0]}, ${showTietKhiGiuaThang[1]} ${showTietKhiGiuaThang[0]}`
                      : ""}
                    <span
                      style={{ paddingLeft: "10px" }}
                      className="to-hide-print"
                    >
                      {dateShowBellowSolarDate
                        ? ` --- Âm Lịch: Ngày ${dateShowBellowSolarDate[0]
                        } Tháng ${dateShowBellowSolarDate[1]} Năm ${dateShowBellowSolarDate[2]
                        } ${ngayKyAmLich ? " --- " + ngayKyAmLich : ""} ${ngayKyTietKhi ? " --- " + ngayKyTietKhi : ""
                        }`
                        : ""}
                    </span>
                  </p>
                </div>
              )}

              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td width="75%" style={{}}>
                      <table className="hourInfo">
                        <tbody>
                          <tr>
                            {dateData.map((item) => {
                              return this.renderTimeInfo(
                                item.header,
                                item.canChi,
                                item.diaChi,
                                item.qua,
                                item.que
                              );
                            })}
                            <td width="20%">
                              <table
                                id="borderTableInfoDate"
                                style={{
                                  marginTop: "5px",
                                  marginRight: "0px",
                                  marginLeft: "25px",
                                }}
                              >
                                <tbody>
                                  <tr id="borderTableInfoDate">
                                    <td
                                      colSpan="2"
                                      id="borderTableInfoDate"
                                      style={{
                                        fontWeight: "bold",

                                        borderRadius: "0px",
                                      }}
                                    >
                                      Thông tin ngày cơ bản
                                    </td>
                                  </tr>
                                  <tr id="borderTableInfoDate">
                                    <td
                                      width="50%"
                                      id="borderTableInfoDate"
                                      style={{
                                        fontSize: "80%",
                                      }}
                                    >
                                      12 Trực
                                    </td>
                                    <td
                                      width="50%"
                                      id="borderTableInfoDate"
                                      style={{
                                        fontSize: "80%",
                                      }}
                                    >
                                      <p
                                        style={{
                                          color:
                                            nhiThapKienTruAndDongCong?.truc ===
                                              Const.trucSao.Kien.name ||
                                              nhiThapKienTruAndDongCong?.truc ===
                                              Const.trucSao.Man.name ||
                                              nhiThapKienTruAndDongCong?.truc ===
                                              Const.trucSao.Dinh.name ||
                                              nhiThapKienTruAndDongCong?.truc ===
                                              Const.trucSao.Thanh.name ||
                                              nhiThapKienTruAndDongCong?.truc ===
                                              Const.trucSao.Khai.name
                                              ? "red"
                                              : "black",
                                        }}
                                      >
                                        {nhiThapKienTruAndDongCong?.truc}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr id="borderTableInfoDate">
                                    <td
                                      id="borderTableInfoDate"
                                      style={{
                                        fontSize: "80%",
                                      }}
                                    >
                                      Đổng Công
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "80%",
                                        paddingTop: "4px",
                                      }}
                                      id="borderTableInfoDate"
                                    >
                                      <Rating
                                        readonly
                                        initialRating={dongCong}
                                        emptySymbol={
                                          <i
                                            className="fa fa-star-o fa-2x"
                                            aria-hidden="true"
                                          ></i>
                                        }
                                        fullSymbol={
                                          <i
                                            className="fa fa-star fa-2x"
                                            style={{
                                              color: "#F5B041",
                                            }}
                                            aria-hidden="true"
                                          ></i>
                                        }
                                      />
                                    </td>
                                  </tr>
                                  <tr id="borderTableInfoDate">
                                    <td
                                      style={{
                                        fontSize: "80%",
                                      }}
                                      id="borderTableInfoDate"
                                    >
                                      28 Sao
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "80%",
                                        color: starC.good ? "red" : "black",
                                      }}
                                      id="borderTableInfoDate"
                                    >
                                      {starC.name}
                                    </td>
                                  </tr>
                                  <tr id="borderTableInfoDate">
                                    <td
                                      style={{
                                        fontSize: "80%",
                                        whiteSpace: "pre-wrap",
                                        width: "200px",
                                        lineHeight: "15px",
                                      }}
                                      id="borderTableInfoDate"
                                    >
                                      {`Hoàng Đạo\n Hắc Đạo`}
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "80%",
                                        color: hoangDao.isHoangDao
                                          ? "red"
                                          : "black",
                                      }}
                                      id="borderTableInfoDate"
                                    >
                                      {hoangDao.Name}
                                    </td>
                                  </tr>
                                  <tr id="borderTableInfoDate">
                                    <td
                                      style={{
                                        fontSize: "80%",
                                      }}
                                      id="borderTableInfoDate"
                                    >
                                      Nạp Âm
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "80%",
                                      }}
                                      id="borderTableInfoDate"
                                    >
                                      {napAm}
                                    </td>
                                  </tr>
                                  <tr id="borderTableInfoDate">
                                    <td
                                      style={{
                                        fontSize: "80%",
                                      }}
                                      id="borderTableInfoDate"
                                    >
                                      Xung Tuổi
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "80%",
                                      }}
                                      id="borderTableInfoDate"
                                    >
                                      {canChiDay?.xungTuoi}
                                    </td>
                                  </tr>
                                  <tr id="borderTableInfoDate">
                                    <td
                                      style={{
                                        fontSize: "80%",
                                      }}
                                      id="borderTableInfoDate"
                                    >
                                      Tài Thần
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "80%",
                                      }}
                                      id="borderTableInfoDate"
                                    >
                                      {taiThan}
                                    </td>
                                  </tr>
                                  <tr id="borderTableInfoDate">
                                    <td
                                      style={{
                                        fontSize: "80%",
                                      }}
                                      id="borderTableInfoDate"
                                    >
                                      Hỷ Thần
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "80%",
                                      }}
                                      id="borderTableInfoDate"
                                    >
                                      {hyThan}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table style={{ marginLeft: "auto" }}>
                                <tbody>
                                  <tr style={{ height: "20px" }}></tr>
                                  <tr>
                                    <td>
                                      <div id="myImg">
                                        <Modal
                                          trigger={
                                            <div>
                                              <img
                                                alt="arrow"
                                                src={arrow}
                                                style={{
                                                  position: "absolute",
                                                  right: "88px",
                                                  top: "-14px",
                                                  height: "211px",
                                                  transform: `rotate(${toaCanChi?.cordCompass})`,
                                                }}
                                              />
                                              <img
                                                alt="LaBan"
                                                src={LaBan}
                                                style={{
                                                  zIndex: "999",
                                                  width: "183px",
                                                  height: "183px",
                                                }}
                                              ></img>
                                              {huongTamSatHour && (
                                                <img
                                                  alt="tamSat"
                                                  src={tamSat}
                                                  style={{
                                                    position: "absolute",
                                                    opacity: 0.5,
                                                    right: "27px",
                                                    top: "-36px",
                                                    height: "255px",
                                                    transform: `rotate(${huongTamSatHour})`,
                                                  }}
                                                />
                                              )}
                                              {huongTamSatDay && (
                                                <img
                                                  alt="tamSat"
                                                  src={tamSat}
                                                  style={{
                                                    position: "absolute",
                                                    opacity: 0.5,
                                                    right: "27px",
                                                    top: "-36px",
                                                    height: "255px",
                                                    transform: `rotate(${huongTamSatDay})`,
                                                  }}
                                                />
                                              )}
                                              {huongTamSatMonth && (
                                                <img
                                                  alt="tamSat"
                                                  src={tamSat}
                                                  style={{
                                                    position: "absolute",

                                                    opacity: 1,
                                                    right: "27px",
                                                    top: "-36px",
                                                    height: "255px",
                                                    transform: `rotate(${huongTamSatMonth})`,
                                                  }}
                                                />
                                              )}
                                              {huongTamSatYear && (
                                                <img
                                                  alt="tamSat"
                                                  src={tamSat}
                                                  style={{
                                                    position: "absolute",

                                                    opacity: 0.5,
                                                    right: "27px",
                                                    top: "-36px",
                                                    height: "255px",
                                                    transform: `rotate(${huongTamSatYear})`,
                                                  }}
                                                />
                                              )}
                                              <img
                                                alt="tamSat"
                                                src={five}
                                                style={{
                                                  position: "absolute",

                                                  opacity: 0.5,
                                                  right: "80px",
                                                  top: "-2px",
                                                  height: "187px",
                                                  transform: `rotate(${queNam?.coordinate})`,
                                                }}
                                              />
                                              {huongNguHoangYear?.isActive && (
                                                <img
                                                  alt="nguHoang"
                                                  src={nguHoang}
                                                  style={{
                                                    position: "absolute",

                                                    opacity: 0.5,
                                                    right: "56px",
                                                    top: "0px",
                                                    height: "183px",
                                                    transform: `rotate(${huongNguHoangYear?.cordi})`,
                                                  }}
                                                />
                                              )}
                                              {huongNguHoangMonth?.isActive && (
                                                <img
                                                  alt="nguHoang"
                                                  src={nguHoang}
                                                  style={{
                                                    position: "absolute",

                                                    opacity: 0.5,
                                                    right: "56px",
                                                    top: "0px",
                                                    height: "183px",
                                                    transform: `rotate(${huongNguHoangMonth?.cordi})`,
                                                  }}
                                                />
                                              )}
                                            </div>
                                          }
                                        >
                                          <Modal.Content
                                            image
                                            className="modal-container"
                                          >
                                            <img
                                              alt="arrow2"
                                              src={arrow2}
                                              style={{
                                                position: "fixed",
                                                top: "50%",
                                                left: "50%",
                                                height: "110%",
                                                transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${toaCanChi?.cordCompass})`,
                                              }}
                                            />
                                            <Image
                                              wrapped
                                              size="massive"
                                              src={LaBanV2}
                                              style={{
                                                position: "fixed",
                                                top: "50%",
                                                left: "50%",
                                                transform: `translate(-50%, -50%) translate(-0px, 0px)`,
                                              }}
                                            />
                                            {huongTamSatHour && (
                                              <img
                                                alt="arrow2"
                                                src={tamSat}
                                                style={{
                                                  opacity: 0.3,
                                                  position: "fixed",
                                                  top: "50%",
                                                  left: "50%",
                                                  height: "132%",
                                                  transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${huongTamSatHour})`,
                                                }}
                                              />
                                            )}
                                            {huongTamSatDay && (
                                              <img
                                                alt="arrow2"
                                                src={tamSat}
                                                style={{
                                                  opacity: 0.3,
                                                  position: "fixed",
                                                  top: "50%",
                                                  left: "50%",
                                                  height: "132%",
                                                  transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${huongTamSatDay})`,
                                                }}
                                              />
                                            )}
                                            {huongTamSatMonth && (
                                              <img
                                                alt="arrow2"
                                                src={tamSat}
                                                style={{
                                                  opacity: 0.3,
                                                  position: "fixed",
                                                  top: "50%",
                                                  left: "50%",
                                                  height: "132%",
                                                  transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${huongTamSatMonth})`,
                                                }}
                                              />
                                            )}
                                            {huongTamSatYear && (
                                              <img
                                                alt="arrow2"
                                                src={tamSat}
                                                style={{
                                                  opacity: 0.3,
                                                  position: "fixed",
                                                  top: "50%",
                                                  left: "50%",
                                                  height: "132%",
                                                  transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${huongTamSatYear})`,
                                                }}
                                              />
                                            )}
                                            <img
                                              alt="arrow2"
                                              src={five}
                                              style={{
                                                opacity: 0.5,
                                                position: "fixed",
                                                top: "50%",
                                                left: "50%",
                                                height: "98%",
                                                transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${queNam?.coordinate})`,
                                              }}
                                            />
                                            {huongNguHoangYear?.isActive && (
                                              <img
                                                alt="arrow2"
                                                src={nguHoang}
                                                style={{
                                                  opacity: 0.5,
                                                  position: "fixed",
                                                  top: "50%",
                                                  left: "50%",
                                                  height: "93%",
                                                  transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${huongNguHoangYear?.cordi})`,
                                                }}
                                              />
                                            )}
                                            {huongNguHoangMonth?.isActive && (
                                              <img
                                                alt="arrow2"
                                                src={nguHoang}
                                                style={{
                                                  opacity: 0.5,

                                                  position: "fixed",
                                                  top: "50%",
                                                  left: "50%",
                                                  height: "93%",
                                                  transform: `translate(-50%, -50%) translate(-0px, 0px) rotate(${huongNguHoangMonth?.cordi})`,
                                                }}
                                              />
                                            )}
                                            <div
                                              style={{
                                                position: "fixed",
                                                right: "0px",
                                                width: "150px",
                                              }}
                                            >
                                              <p>
                                                15 độ{" "}
                                                <mark
                                                  style={{
                                                    backgroundColor:
                                                      "#ff00007a",
                                                  }}
                                                >
                                                  Thái Tuế
                                                </mark>
                                              </p>
                                              <br></br>
                                              <p>
                                                15 độ{" "}
                                                <mark
                                                  style={{
                                                    backgroundColor:
                                                      "#0080008a",
                                                  }}
                                                >
                                                  Tuế Phá
                                                </mark>
                                              </p>
                                              <br></br>
                                              <p>
                                                45 độ{" "}
                                                <mark
                                                  style={{
                                                    backgroundColor:
                                                      "#ffff00ad",
                                                  }}
                                                >
                                                  Ngũ Hoàng
                                                </mark>
                                              </p>
                                              <br></br>
                                              <p>
                                                90 độ{" "}
                                                <mark
                                                  style={{
                                                    backgroundColor:
                                                      "#0000ff80",
                                                  }}
                                                >
                                                  Tam Sát
                                                </mark>
                                              </p>
                                              <br></br>
                                            </div>
                                          </Modal.Content>
                                        </Modal>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
            {/* phần này nè */}
            <div className="to-hide-print">
              <div
                style={{
                  textAlign: "center",
                  marginBottom: "15px",
                  display: "inline-block",
                  width: "50%",
                }}
              >
                <Button
                  style={{ backgroundColor: "red", color: "white" }}
                  onClick={() => {
                    const getObj = localStorage.getItem(mdfive);
                    const dataSavedStorage = JSON.parse(getObj);
                    // this.setState({ openKyMonNgayGio: !this.state.openKyMonNgayGio });

                    if (dataSavedStorage?.isActiveChuyenCung) {
                      this.setState({
                        openKyMonChuyenCung: !this.state.openKyMonChuyenCung,
                      });
                    } else {
                      alert(
                        `Vui lòng nhập code để có thể sử dụng chức năng Kỳ môn độn giáp này`
                      );
                    }
                  }}
                >{`${this.state.openKyMonChuyenCung ? "Ẩn" : "Hiện"
                  } chi tiết kỳ môn độn giáp Chuyển Cung`}</Button>
              </div>
              <div
                style={{
                  textAlign: "center",
                  marginBottom: "15px",
                  display: "inline-block",
                }}
              >
                <Button
                  style={{ backgroundColor: "red", color: "white" }}
                  onClick={() => {
                    const getObj = localStorage.getItem(mdfive);
                    const dataSavedStorage = JSON.parse(getObj);
                    // this.setState({ openKyMonNgayGio: !this.state.openKyMonNgayGio });

                    if (dataSavedStorage?.isActiveAmBanMaoSon) {
                      this.setState({
                        openKyMonMaoSon: !this.state.openKyMonMaoSon,
                      });
                    } else {
                      alert(
                        `Vui lòng nhập code để có thể sử dụng chức năng Kỳ môn độn giáp này`
                      );
                    }
                  }}
                >{`${this.state.openKyMonMaoSon ? "Ẩn" : "Hiện"
                  } chi tiết kỳ môn độn giáp Âm Bàn Mao Sơn`}</Button>
              </div>
              <section style={{ marginBottom: "25px", display: "flex" }}>
                <Collapse isOpened={this.state.openKyMonChuyenCung}>
                  <div className="flex-direction-row">
                    {chiDay && chiMonthLunar && result1080cucHour_new && (
                      <div>
                        KỲ MÔN CHUYỂN CUNG THEO GIỜ:
                        <PhiTinhDetailNew
                          showLogo={this.state.openKyMonChuyenCung}
                          data1080cuc={result1080cucHour_new}
                          hour={this.state.date.getHours()}
                          chiDay={chiDay}
                          isSauDongChi={isSauDongChi}
                          chiMonth={chiMonthLunar}
                        ></PhiTinhDetailNew>
                      </div>
                    )}
                  </div>
                </Collapse>
                <Collapse isOpened={this.state.openKyMonMaoSon}>
                  <div className="flex-direction-row">
                    <div style={{ width: "10px" }}></div>
                    {chiDay && chiMonthLunar && result1080cucHourMaoSon && (
                      <div>
                        KỲ MÔN ÂM BÀN MAO SƠN:
                        <PhiTinhDetailNew
                          showLogo={this.state.openKyMonMaoSon}
                          data1080cuc={result1080cucHourMaoSon}
                          hour={this.state.date.getHours()}
                          chiDay={chiDay}
                          isSauDongChi={isSauDongChi}
                          chiMonth={chiMonthLunar}
                        ></PhiTinhDetailNew>
                      </div>
                    )}
                  </div>
                </Collapse>
              </section>
            </div>

            <div className="to-hide-print">
              <div style={{ textAlign: "center" }}>
                <Button
                  style={{ backgroundColor: "red", color: "white" }}
                  onClick={() => {
                    const getObj = localStorage.getItem(mdfive);
                    const dataSavedStorage = JSON.parse(getObj);
                    // this.setState({ openKyMonNgayGio: !this.state.openKyMonNgayGio });

                    if (dataSavedStorage?.activeSearch) {
                      this.setState({
                        openKyMonNgayGio: !this.state.openKyMonNgayGio,
                      });
                    } else {
                      alert(
                        `Vui lòng bấm nút 'TÌM KIẾM TỰ ĐỘNG' và nhập code để có thể sử dụng chức năng Kỳ môn độn giáp này`
                      );
                    }
                  }}
                >{`${this.state.openKyMonNgayGio ? "Ẩn" : "Hiện"
                  } chi tiết Kỳ Môn Độn Giáp Phi Cung Lưu Bá Ôn - Ngày và Giờ`}</Button>
              </div>
              <Collapse isOpened={this.state.openKyMonNgayGio}>
                <section>
                  <div className="flex-direction-row">
                    {chiDay && chiMonthLunar && result1080cucHour && (
                      <div>
                        KỲ MÔN ĐỘN GIÁP THEO GIỜ:
                        <PhiTinhDetail
                          showLogo={this.state.openKyMonNgayGio}
                          data1080cuc={result1080cucHour}
                          hour={this.state.date.getHours()}
                          chiDay={chiDay}
                          isSauDongChi={isSauDongChi}
                          chiMonth={chiMonthLunar}
                        ></PhiTinhDetail>
                      </div>
                    )}
                    <div style={{ width: "10px" }}></div>
                    {starDay && chiMonthLunar && result1080cucDate && (
                      <div>
                        KỲ MÔN ĐỘN GIÁP THEO NGÀY:
                        <PhiTinhDetail
                          showLogo={this.state.openKyMonNgayGio}
                          data1080cuc={result1080cucDate}
                          day={starDay}
                          chiDay={chiDay}
                          isSauDongChi={isSauDongChi}
                          chiMonth={chiMonthLunar}
                        ></PhiTinhDetail>
                      </div>
                    )}
                  </div>
                </section>
                <Collapse isOpened={this.state.openKyMonThangNam}>
                  <div style={{ height: "10px" }}></div>
                  <section>
                    <div className="flex-direction-row">
                      {dateTietKhi && chiMonthLunar && result1080cucMonth && (
                        <div>
                          KỲ MÔN ĐỘN GIÁP THEO THÁNG:
                          <PhiTinhDetail
                            showLogo={this.state.openKyMonThangNam}
                            data1080cuc={result1080cucMonth}
                            month={dateTietKhi}
                            chiDay={chiDay}
                            isSauDongChi={isSauDongChi}
                            chiMonth={chiMonthLunar}
                          ></PhiTinhDetail>
                        </div>
                      )}
                      <div style={{ width: "10px" }}></div>
                      {dateTietKhi && chiMonthLunar && result1080cucYear && (
                        <div>
                          KỲ MÔN ĐỘN GIÁP THEO NĂM:
                          <PhiTinhDetail
                            showLogo={this.state.openKyMonThangNam}
                            data1080cuc={result1080cucYear}
                            year={dateTietKhi}
                            chiDay={chiDay}
                            isSauDongChi={isSauDongChi}
                            chiMonth={chiMonthLunar}
                          ></PhiTinhDetail>
                        </div>
                      )}
                    </div>
                  </section>
                </Collapse>
                <div style={{ textAlign: "center" }}>
                  <Button
                    onClick={() =>
                      this.setState({
                        openKyMonThangNam: !this.state.openKyMonThangNam,
                      })
                    }
                  >{`${this.state.openKyMonThangNam ? "Ẩn" : "Hiện"
                    } chi tiết kỳ môn độn giáp tháng và năm`}</Button>
                </div>
              </Collapse>
            </div>

            <section>
              <td width="100%" style={{}}>
                {dateShowBellowSolarDate ? (
                  <p
                    className="to-show-print"
                    style={{
                      left: "-20px",
                      position: "absolute",
                      top: "450px",
                    }}
                  >
                    {`Dương Lịch: Ngày ${date.getDate()} Tháng ${date.getMonth() + 1
                      } Năm ${date?.getFullYear()}\n Âm Lịch: Ngày ${dateShowBellowSolarDate[0]
                      } Tháng ${dateShowBellowSolarDate[1]} Năm ${dateShowBellowSolarDate[2]
                      }`}
                  </p>
                ) : null}
                <div
                  style={{
                    width: "1080px",
                    height: "100%",
                    position: "absolute",
                    zIndex: -1,
                  }}
                >
                  <img
                    id="LoadingImage"
                    src={Logo}
                    alt="logo"
                    style={{
                      // animation: `spin 10s linear infinite`,
                      justifyContent: "center",
                      opacity: "0.3",
                      alignSelf: "center",
                    }}
                  />
                </div>
                <table width="100%">
                  <tbody>
                    <tr height="100%">
                      <td width="100%" style={{}}>
                        <table
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <tbody>
                            <tr>
                              {this.renderShould(
                                "Hung thần",
                                kiengKy.hungThan,
                                "#707070"
                              )}
                              {this.renderShould(
                                "Cát thần",
                                kiengKy.catThan,
                                "#F05828"
                              )}
                              {this.renderShould("Nên", kiengKy.nen, "#F05828")}
                              {this.renderShould(
                                "Kỵ",
                                kiengKy.khongNen,
                                "#707070"
                              )}
                              <td width="10%">
                                <CuuCungPhiTinh
                                  starADay={starDay}
                                  dateLunar={dateTietKhi}
                                  chiHour={getHour.getHours()}
                                  chiDay={chiDay}
                                  isSauDongChi={isSauDongChi}
                                ></CuuCungPhiTinh>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </section>
            <div style={{ height: "10px" }} className="to-hide-print"></div>
            <section>
              <SaoThan
                chiMonth={chiMonthLunar}
                thaiDuongThaiAm={thaiDuongThaiAm}
              />
            </section>
            <div style={{ height: "10px" }} className="to-hide-print"></div>
            <section>
              <HourStar
                data={hourDate}
                chiDay={chiDay}
                isSauDongChi={isSauDongChi}
                canDay={canDay}
                chiMonth={chiMonthLunar}
              />
            </section>
            {/* ----Header---- */}
          </div>
          <div style={{ pageBreakBefore: "always" }}></div>
          <div id="page-title-note" style={{ display: "none" }}>
            <h2>GHI CHÚ</h2>
          </div>
          <div
            id="page-note"
            style={{ display: "none" }}
            dangerouslySetInnerHTML={{ __html: this.state.dataEditor }}
          ></div>
        </div>
      </div>
    );
  }
}

export default TrachNhat;
