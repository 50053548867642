import * as firebase from 'firebase';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/auth';

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import Logo from '../src/logo.png';

import Footer from './components/FooterPage';
import TestKyMonDonGiap from './pages/HomePage';
import SearchKyMonDonGiap from './pages/SearchKyMonDonGiap';
import SearchPage from './pages/SearchPage';

import SearchQuai from './pages/SearchQuai';
import md5 from 'md5';
import Main from './pages/HomePage';

const mdfive = md5('HuyenKhongTamNguyenFengShuiWebSiteHitekTuongMinh');
export default function BasicExample() {
  var database = {
    apiKey: 'AIzaSyAqDXUKgULXkPDQIs2Le_beT6R0_JcmyY8',
    authDomain: 'tmfs-yi-jing.firebaseapp.com',
    databaseURL: 'https://tmfs-yi-jing.firebaseio.com',
    projectId: 'tmfs-yi-jing',
    storageBucket: 'tmfs-yi-jing.appspot.com',
    messagingSenderId: '331042421722',
    appId: '1:331042421722:web:862f72fc6b6c3ed5419ee3',
  };

  const firebaseConfig = {
    apiKey: 'AIzaSyA9A_F8LJdj8OiVsSNQqmly3xFXVwJulxw',
    authDomain: 'ky-mon.firebaseapp.com',
    databaseURL: 'https://ky-mon.firebaseio.com',
    projectId: 'ky-mon',
    storageBucket: 'ky-mon.appspot.com',
    messagingSenderId: '135312632635',
    appId: '1:135312632635:web:4c555b879296f6bc59a51e',
  };
  
  

  firebase.initializeApp(firebaseConfig);
  var otherProject = firebase.initializeApp(database, 'database');
 const AuthDB = ()=>{

    const auth = firebase.app("database").auth();
    auth.setPersistence(firebase.auth.Auth.Persistence.SESSION)
    .then(() => {
      return auth.signInWithEmailAndPassword( process.env.REACT_APP_EMAIL_AUTH, process.env.REACT_APP_PASS_AUTH);
    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
    });
  
  }
  AuthDB();
  // var otherFirestore = otherProject.firestore();
  const getObj = localStorage.getItem(mdfive);
  const dataSavedStorage = JSON.parse(getObj);
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Main} />

        {/* <Home /> */}
        {/* </Route> */}
        {/* <Route exact path="/SearchKyMonDonGiapHour" component={SearchKyMonDonGiap} /> */}
        {/* <Route exact path="/SearchQuai" component={SearchQuai} /> */}
        <Route exact path="/TrachNhat" component={Home} />

        {/* <LaBanFullSize /> */}
        {/* <Route exact path="/NewHomeUI" component={TestKyMonDonGiap} /> */}
        {/* </Route> */}
        {dataSavedStorage?.activeSearch ? <Route exact path="/SearchPage" component={SearchPage} /> : <Redirect to="/" />}
        {/* <TestKyMonDonGiap /> */}
        {/* </Route> */}
      </Switch>
      <Footer></Footer>
    </Router>
  );
}
